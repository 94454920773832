import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/programsoffered';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import whatsapp from '../../../assets/images/icons/whatsapp.png';

// import point1 from '../../../assets/images/icons/image1.png';
// import coming_soon from '../../../assets/images/icons/coming_soon.png';
import SideManu from './sidebar_programs';
// import PDFview from './pdfgenerate';

// import './app.css'; // Assuming the CSS is in App.css

import Syllabusfile from '../../../assets/images/programsoffered/syllabus.pdf';
import Alert_image from '../../../assets/images/programsoffered/alert.gif';
import Condidates_file from './files/condidates.pdf';
import Fee_Details from './files/fee_details.pdf';
import Ph_D_AU_Regulations_2024 from './files/Ph_D_AU_Regulations_2024.pdf';
import Part from './files/Part time.pdf';
import Full from './files/Full time.pdf';
import General_Instructions from './files/General_Instructions.pdf';
import Eligible_Candidates from './files/Eligible_Candidates.pdf';
import Eligible_supervisors from './files/eligible_supervisors.pdf';

import Provisionally_selected from './files/Provisionally selected students for Ph.D. Jan.pdf';












const PGPrograms = () => {
const styles = {
  center: {
    margin: '0 auto'
  },
  awesome: {
    fontFamily: 'futura',
    fontStyle: 'italic',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    color: '#313131',
    fontSize: '26px',
    fontWeight: 'bold',
    WebkitAnimation: 'colorchange 20s infinite alternate'
  }
};

const keyframes = `
  @-webkit-keyframes colorchange {
    0% { color: blue; }
    10% { color: #8e44ad; }
    20% { color: #1abc9c; }
    30% { color: #d35400; }
    40% { color: blue; }
    50% { color: #34495e; }
    60% { color: blue; }
    70% { color: #2980b9; }
    80% { color: #f1c40f; }
    90% { color: #2980b9; }
    100% { color: pink; }
  }
`;
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">

                    <StudyBreadcrumb pageTitle="Ph.D Programs" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div id="overview" class="container">
                                                    <br/>
                                                  {/* <div> */}
                                                    {/* <marquee class="GeneratedMarquee scolling" direction="left" scrollamount="8" behavior="scroll" >
                                                    <Link to={Eligible_Candidates} target="blank"> <b style={{color:"#00306e"}} className='scolling'>   Eligible Candidates for Ph.D. Interview -JAN 2025 <img  src={Alert_image}  style={{height:'40px'}}></img></b></Link> */}

                                                    {/* <Link to={General_Instructions} target="blank"> <b style={{color:"#198754"}} className='scolling'>  General Instructions to attend Interview  <img src={Alert_image} style={{height:'40px'}}></img></b></Link> */}
                                                    {/* <b style={{color:"#198754"}} className='scolling'>   General Instructions to attend Interview <img  style={{height:'40px'}}></img> </b> */}
                                                    {/* </marquee></div> */}

                                                    
                                                <div className="campus_sec1 row">
                                                    <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to={Provisionally_selected}  target ="_blank" className="more-about " style={{ width: "100%" }}> Provisionally selected students for <br/>Ph.d admission -Jan 2025 session <img src={Alert_image}  className ='Alert'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to={Eligible_supervisors}  target ="_blank" className="more-about" style={{ width: "100%" }}>  Eligible Supervisors and Vacant Positions for Jan 2025 Session<img className ='Alert'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>

                                                   <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to={Condidates_file}  target ="_blank" className="more-about" style={{ width: "100%" }}>  Instructions to the Ph.D. Candidates <img className ='Alert'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>
                                                   
                                                  
                                                    
                                                    </div>
                                                    <br/>
                                                   
                                                    <div className="campus_sec1 row">
                                                    <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to="../uploads/programs_offered/International.jpg"  target ="_blank" className="more-about" style={{ width: "100%" }}>  Ph.D admissions(International) <img className ='Alert'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to="../uploads/programs_offered/national.jpg"  target ="_blank" className="more-about" style={{ width: "100%" }}>  Ph.D admissions(National) <img className ='Alert'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="text-center">
                                                            <Link to="https://apply.adityauniversity.in/" style={{ width: "100%" }} className="more-about" target="blank"> Ph.D admissions Jan-25 <br/><b style={{color:'#0091ea'}}>Apply here</b>  <img  className ='Alert'  style={{height:'40px'}}/></Link>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <br/>
                                                <div className="campus_sec1 row">
                                                   <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to={Full}  target ="_blank" className="more-about" style={{ width: "100%" }}>  Academic Calendar - Ph.D <br/>Full time <img className ='Alert'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to={Part}  target ="_blank" className="more-about" style={{ width: "100%" }}> Academic Calendar - Ph.D <br/>Part time<img className ='Alert'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                            <div className="text-center">
                                                                <Link to={Fee_Details}  target ="_blank" className="more-about" style={{ width: "100%" }}>  Fee Details <img className ='Alert_1'  style={{height:'40px'}}/></Link>
                                                            </div>
                                                    </div>
                                                   
                                                    
                                                </div>

                                                    
                                                    {/* <center><img src={coming_soon} style={{height:"350px",width:"350px"}}/></center> */}
                                                    <p className='name'>Doctoral programmes are designed to cultivate advanced research skills, foster critical thinking, and prepare scholars to become leaders in academia, industry, and beyond. Our Doctoral (Ph.D) programs are at the forefront of engineering research, encouraging scholars to pursue fundamental research that advances the boundaries of engineering knowledge. With access to state-of-the-art facilities and mentorship from distinguished faculty, our doctoral candidates are well-positioned to make significant contributions to their fields. </p>
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}>Ph.D. Programmes are offered in the disciplines of :</b> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Engineering (EEE, ECE, CSE, Mechanical, Civil, Agriculture, Petroleum and Mining) .</span> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Management</span> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Computer Applications</span> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Mathematics</span> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Physics</span> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Chemistry</span> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> English</span> <br />

                                                </div>
                                                <br />
                                                
                                                
                                                {/* <div>
                                                    <style>
                                                        {keyframes}
                                                    </style>
                                                    <div style={styles.awesome} className="center">
                                                    <img src='https://cdn.dribbble.com/users/3337757/screenshots/6650748/notif-dribbble-video.gif' style={{height:'40px'}}></img> Notification for Ph.D admissions will be released on 30-06-2024.<img src='https://cdn.dribbble.com/users/3337757/screenshots/6650748/notif-dribbble-video.gif' style={{height:'40px'}}></img>
                                                    </div>
                                                </div> */}
                                                <center> 
                                                <div className="campus_sec1">
                                                   
                                                    <div className="col-lg-6">
                                                        <div className="text-center">
                                                            <Link to="../uploads/programs_offered/Ph D Entrance Syllabus.pdf" style={{ width: "100%" }} className="more-about" target="blank"> Ph.D entrance Examination Syllabus Jan-2025 <img  className ='Alert1'  style={{height:'40px'}}/></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                </center>

                                                <br />
                                                <div className="campus_sec1 row">
                                                    {/* <div className="col-lg-6">
                                                        <div className="text-center">
                                                            <Link to="/PhD_admission_form" style={{ width: "100%" }} className="more-about" target="blank"> Apply Now   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-6">
                                                        <div className="text-center">
                                                            <Link to={Ph_D_AU_Regulations_2024} style={{ width: "100%" }} className="more-about" target="blank"> Ph.D  Regulations 2024 </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                            <div className="text-center">
                                                                <Link to="/phd_syllabus"  target ="_blank" className="more-about" style={{ width: "100%" }}> Ph.D Course work Syllabus </Link>
                                                            </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                            <div className="text-center">
                                                                <Link to={Syllabusfile}  target ="_blank" className="more-about" style={{ width: "100%" }}> Important Dates & Exam Syllabus  <img  style={{height:'25px'}}></img> </Link>
                                                            </div>
                                                    </div> */}
                                                </div>
                                                <br/>
                                                {/* <center>
                                                <div className="campus_sec1 row" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div className="col-lg-6">
                                                            <div className="text-center">
                                                                <Link to={Syllabusfile}  target ="_blank" className="more-about" style={{ width: "100%" }}> Important Dates & Exam Syllabus  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </center> */}
                                                {/* <br/>
                                                <center><span className='name'> For any queries, contact: <b>7036266665</b> <img src={whatsapp} style={{ height: "25px" }} />  </span>
                                                <br/>
                                                Submitted applications can be downloaded here. </center> 
                                               
                                                <PDFview/> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default PGPrograms;