import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar = () => {
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                         <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/chancellor_msg' || window.location.pathname === '/chancellor_msg' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/chancellor_msg">Chancellor's Message</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/pro-chancellor_msg' || window.location.pathname === '/pro-chancellor_msg' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/pro-chancellor_msg">Pro-Chancellor's Message</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/deputy_pro_chancellor_msg' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/deputy_pro_chancellor_msg"> Deputy Pro-Chancellor's Message</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/vice_chancellor_msg' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/vice_chancellor_msg"> Vice Chancellor's Message</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/pro_vice_chancellor_msg' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/pro_vice_chancellor_msg"> Pro Vice-Chancellor (Academics) Message</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/registrar_msg' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/registrar_msg"> Registrar</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;