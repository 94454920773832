import React from 'react';
import "./admission.css";
import campus1 from '../../assets/images/campus/CULTURAL EVENTS.jpg'
import campus2 from '../../assets/images/campus/SPORTS EVENTS.jpg'
import campus3 from '../../assets/images/campus/INCLUSIVE ECOSYSTEM.jpg'
import campus4 from '../../assets/images/campus/Spiritual Harmony.jpg'

import facebook from './images/campus_life/facebook1.png'
import twitter from './images/campus_life/twitter.png'
import instagram from './images/campus_life/instagram.png'
import SectionTitle from '../../components/SectionTitle'



const Service = () => {

    return (
        <div className="pt---100 pb---70">
            <div className="container">
            <SectionTitle Title="Campus Life" />
            {/* <center><b className="life_at_aditya"> Campus Life <span style={{color:'#f05718'}}></span> </b></center> */}
                <div className="row card_gap">
                    <div class="club-card">
                        <div class="social-icons">
                            <a href="https://www.facebook.com/adityauniversity/" class="icon facebook"><img  className='image_events' src={facebook} /></a>
                            <a href="https://twitter.com/adityauniv" class="icon twitter"><img className='image_events' src={twitter} /></a>
                            <a href="https://www.instagram.com/aditya_university/" class="icon instagram"><img  className='image_events' src={instagram} /></a>
                        </div>
                        <div class="image">
                        <img src={campus1} className="" />
                        </div>
                        <div class="title">
                            <h3 className='event_name'>ABHINAYA CLUB</h3>
                        </div>
                    </div>

                    <div class="club-card">
                        <div class="social-icons">
                            <a href="https://www.facebook.com/adityauniversity/" class="icon facebook"><img  className='image_events' src={facebook} /></a>
                            <a href="https://twitter.com/adityauniv" class="icon twitter"><img className='image_events' src={twitter} /></a>
                            <a href="https://www.instagram.com/aditya_university/" class="icon instagram"><img  className='image_events' src={instagram} /></a>
                        </div>
                        <div class="image">
                        <img src={campus2} className="" />
                        </div>
                        <div class="title">
                            <h3 className='event_name'>Sports in Action</h3>
                        </div>
                    </div>

                    <div class="club-card">
                        <div class="social-icons">
                            <a href="https://www.facebook.com/adityauniversity/" class="icon facebook"><img  className='image_events' src={facebook} /></a>
                            <a href="https://twitter.com/adityauniv" class="icon twitter"><img className='image_events' src={twitter} /></a>
                            <a href="https://www.instagram.com/aditya_university/" class="icon instagram"><img  className='image_events' src={instagram} /></a>
                        </div>
                        <div class="image">
                        <img src={campus3} className="" />
                        </div>
                        <div class="title">
                            <h3 className='event_name'>Inclusive Ecosystem</h3>
                        </div>
                    </div>

                    <div class="club-card">
                        <div class="social-icons">
                            <a href="https://www.facebook.com/adityauniversity/" class="icon facebook"><img  className='image_events' src={facebook} /></a>
                            <a href="https://twitter.com/adityauniv" class="icon twitter"><img className='image_events' src={twitter} /></a>
                            <a href="https://www.instagram.com/aditya_university/" class="icon instagram"><img  className='image_events' src={instagram} /></a>
                        </div>
                        <div class="image">
                        <img src={campus4} className="" />
                        </div>
                        <div class="title">
                            <h3 className='event_name'>Spiritual Harmony</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;