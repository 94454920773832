import React from 'react';

const Careers = () => {
  return (
    <div className="container-fluid" style={{ padding: 0, margin: 0 ,backgroundColor:'#000'}}>
      {/* Making the iframe responsive */}
      <iframe
        src="https://aec.edu.in/rdecs2025/"
        style={{
          width: '100%',
          height: '99vh', // Set to viewport height
          border: 'none', // Removes iframe border
        }}
        title="Careers Page"
      />
    </div>
  );
}

export default Careers;
