import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css";
import SideManu from './sidebar_examination';
// import Linkimage from './images/link.gif';
// import Academic_calendar_image from './images/calendar.png';
import Academic_calendar_image from './images/exam.png';


import MBA_paper_1 from './model_papers/01 Management And Organization Behaviour Model  Paper.pdf';
import MBA_paper_2 from './model_papers/02 Accounting For Managers Model Paper.pdf';
import MBA_paper_3 from './model_papers/03 Managerial Economics Model Paper.pdf';
import MBA_paper_4 from './model_papers/04 Business Statistics And Analysis For Decision Making Model Paper.pdf';
import MBA_paper_5 from './model_papers/05 Legal And Business Environment Model Paper.pdf';
import MBA_paper_6 from './model_papers/06 Business Research Methodology Model Paper.pdf';

// Define the program data with an array of curriculum names and links
const programData = [
    {
        id: 0,
        title: 'MBA',
        curriculum_name: [
            'Management And Organization Behaviour',
            'Accounting For Managers',
            'Managerial Economics',
            'Business Statistics And Analysis For Decision Making',
            'Legal And Business Environment',
            'Business Research Methodology'
        ],
        curriculum: [
            MBA_paper_1,
            MBA_paper_2,
            MBA_paper_3,
            MBA_paper_4,
            MBA_paper_5,
            MBA_paper_6
        ]
    },
];

const Curriculum = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Initialize to make the first item open by default

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Examination Centre" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research" id="">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div>
                                                        {programData.map(({ id, title, curriculum, curriculum_name}) => {
                                                            console.log(`Rendering Program ID: ${id}`);
                                                            console.log("Curriculum Links:", curriculum);
                                                            console.log("Curriculum Names:", curriculum_name);

                                                            return (
                                                                <React.Fragment key={id}>
                                                                    <button
                                                                        className={`accordion ${activeIndex === id ? 'active' : ''}`}
                                                                        onClick={() => toggleAccordion(id)}
                                                                    >
                                                                        <img src={Academic_calendar_image} style={{ height: '40px' }} alt={title} />
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none', marginBottom: '14px' }}>
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>I Semester</th>
                                                                                    <th style={{ textAlign: 'center' }}>MBA2024</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {curriculum.map((curr, index) => {
                                                                                    if (!curr || !curriculum_name[index]) {
                                                                                        console.error(`Missing curriculum data at index ${index} for program ${title}`);
                                                                                        return null; // Skip rendering if data is missing
                                                                                    }
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{curriculum_name[index]}</td>
                                                                                            <td style={{ textAlign: 'center' }}>
                                                                                                <a href={curr} target="_blank" rel="noopener noreferrer" style={{ color: "#000" }} className='namehover'>
                                                                                                    View
                                                                                                </a>
                                                                                                {/* <img src={Linkimage} style={{ height: '25px', marginLeft: '5px' }} alt="link icon" /> */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                        
                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />
        </>
    );
}

export default Curriculum;
