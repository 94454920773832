import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/iqac';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';

import SideManu from './sidebar_msg';
import Chairman_img from './images/chairman.jpg';





const accreditations = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Deputy Pro-Chancellor's Message" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div className="col-lg-8" style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '20px' }}>
                                                <div className="about__content about__content1">
                                                                           <center><img src={Chairman_img} alt="About" /></center>
                                                    {/* <b style={{ fontSize: '18px', color: "#D2093C" }}>Dreams alone won't bring success; it takes sweat, determination, and action
                                                    </b> */}
                                                    <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px'}}>
                                                    At Aditya University, our commitment to nurturing leaders and advancing knowledge is guided by our Vision of excellence in education, innovation in research, and dedicated service to society. We provide an environment that merges academic rigor with a culture of support, equipping our students for the challenges of a dynamic world.</p>
                                                    <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                                    We emphasize interdisciplinary learning and practical exposure, preparing our students not only for academic success but for responsible global citizenship. By fostering a culture of innovation and sustainability, we contribute to impactful societal advancements. At Aditya University, we envision a future where education is a powerful driver of holistic growth and positive change. I invite you to join us in this journey of transformation and excellence, where every step is a stride toward realizing a shared vision of progress for our community, our nation, and our world.
                                                    </p>
                                                    <b style={{ float: 'right' }}>
                                                        Best regards,<br />
                                                        Dr. M. Sreenivasa Reddy<br />
                                                        Deputy Pro-Chancellor<br />
                                                        Aditya university
                                                    </b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default accreditations;