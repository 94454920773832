import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
const Sidebar = () => {
    const [submenuVisible, setSubmenuVisible] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const location = useLocation();

    const toggleSubmenu = () => {
        setSubmenuVisible(!submenuVisible);
    };

    const handleSubmenuClick = (path) => {
        setActiveSubmenu(path);
    };
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                        <li class="navbar-title text-center">
                            <a class="navbar-brand text-light1 " href="#" style={{padding: '12px 43px 12px 67px'}}>Examination Centre</a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/examination_centre' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/examination_centre">Examination Notifications</Link>
                        </li>
                        <li
                            className={`nav-item ${['/MBA_model_papers', '/B_Tech_model_papers','/M_Tech_model_papers', '/MBAI_model_papers'].includes(location.pathname)  ? 'active' : ''}`}
                            onMouseEnter={toggleSubmenu}
                            onMouseLeave={toggleSubmenu}
                            onClick={toggleSubmenu}
                        >
                            <Link className={`nav-link ${['/MBA_model_papers', '/B_Tech_model_papers','/M_Tech_model_papers', '/MBAI_model_papers'].includes(location.pathname) || submenuVisible ? 'active' : ''}`} to="#">Model Question Papers</Link>
                            {submenuVisible && (
                                <ul className="submenu">
                                     <li
                                        className={`nav-item ${location.pathname === '/B_Tech_model_papers' || activeSubmenu === '/B_Tech_model_papers' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/B_Tech_model_papers')}
                                    >
                                        <Link className="nav-link" to="/B_Tech_model_papers"> B.Tech </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/M_Tech_model_papers' || activeSubmenu === '/M_Tech_model_papers' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/M_Tech_model_papers')}
                                    >
                                        <Link className="nav-link" to="/M_Tech_model_papers"> M.Tech </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/MBA_model_papers' || activeSubmenu === '/MBA_model_papers' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/MBA_model_papers')}
                                    >
                                        <Link className="nav-link" to="/MBA_model_papers"> MBA </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/MBAI_model_papers' || activeSubmenu === '/MBAI_model_papers' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/MBAI_model_papers')}
                                    >
                                        <Link className="nav-link" to="/MBAI_model_papers"> MBA(Integrated) </Link>
                                    </li>
                                    
                                </ul>
                            )}
                        </li>
                    <br/>
                        
                    </ul>
                </nav>
                    {/* <div className="campus_sec1">
                             <div className="text-center">
                                <Link to="/academics" className="more-about"  style={{ width: "100%" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Home </Link>
                            </div>
                    </div> */}
            </div>
        </div>
    );
}

export default Sidebar;