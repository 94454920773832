// import React, { useEffect, useState } from "react";
// import image from "./images/adminssion.png";

// const Apply = () => {
//   const [showImage, setShowImage] = useState(true);
//   const [lastScrollPos, setLastScrollPos] = useState(0);

//   useEffect(() => {
//     const handleScroll = () => {
//       const currentScrollPos = window.scrollY;
//       if (currentScrollPos > lastScrollPos) {
//         // User is scrolling down
//         setShowImage(false);
//       } else {
//         // User is scrolling up
//         setShowImage(true);
//       }
//       setLastScrollPos(currentScrollPos);
//     };

//     window.addEventListener("scroll", handleScroll);

//     // Cleanup event listener on component unmount
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [lastScrollPos]);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-12">
//           {showImage && (
//             <div className="admission_ug_pg" style={{ display: "block" }}>
//               <img src={image} className="ug_pg" alt="image" />
//             </div>
//           )}
//         </div>
//       </div>
      
//     </div>
//   );
// };

// export default Apply;

import React, { useEffect, useState } from "react";
import image from "./images/adminssion.png";

const Apply = () => {
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setShowImage(currentScrollPos <= 100); // Hide image if scrolled beyond 40px
    };

    window.addEventListener("scroll", handleScroll);

    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 ">
          {showImage && (
            <div className="admission_ug_pg admission_ug_pg" style={{ display: "block" }}>
             <a href="https://apply.adityauniversity.in/"> <img src={image} className="ug_pg" alt="image" /></a>
            </div>
          )}
        </div>
      </div>
     
    </div>
  );
};

export default Apply;



