import React, { useState, useEffect } from 'react';
import './apply_now.css'; // Create a separate CSS file for styles
import downimage from './down.png';


const DropdownExample = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
 

  const handleClickOutside = (event) => {
    if (!event.target.matches('.dropbtn_apply')) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);
 

  return (
    <div>
    <div className="dropdown_apply" style={{float: "inline-end"}}>
      <button onClick={toggleDropdown} className="dropbtn_apply button-alumini2 widthsize">
        Apply Now 
      </button>
      <div id="myDropdown" style={{marginTop :'2px'}} className={`dropdown_apply-content ${showDropdown ? 'show' : ''}`}>
        <a className='names' href="https://apply.adityauniversity.in/" target="_blank" rel="noopener noreferrer">
          <b>National</b>
        </a>
        <a className='names' href="https://www.aditya.ac.in/international/" target="_blank" rel="noopener noreferrer">
          <b>International</b>
        </a>
      </div>
    </div>
  </div>
  );
};

export default DropdownExample;
