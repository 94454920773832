import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/ab3.png';
import placements from '../../assets/images/about/Placements.jpg';
import infrastructure from '../../assets/images/about/Infrastructure.jpg';
import faculty from '../../assets/images/about/Faculty.jpg';
import academics from '../../assets/images/about/Academics.jpg';
import amenties from '../../assets/images/about/Amenities.jpg';
import point from '../../assets/images/icons/image.png';


import shapeImg1 from '../../assets/images/about/shape_02.png';

const WhyADITYApart = () => {

    return (
        <div class=" about3__area p-relative pb---60" style={{ marginTop: "30px" }}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={placements} alt="About" width="100%"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">

                            <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Placements</h2>
                            <div class="degree-list-container">
                                <ul class="degree-list">
                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  100% placements for past 19 years for all registered and eligible students.</span></li>
                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  3125+ Placement offers from 260+ Industry-connect Companies, in 2023-24 academic year, with Rs.35.36 Lakhs per annum as highest pay package and Rs.5.26 Lakhs per annum as average pay package.</span></li>
                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Full semester Industry-Oriented project to ensure all students work in industry prior to graduation.</span></li>
                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Exclusive focus on placement training and pre-placement offers.</span></li>
                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Internships in globally acclaimed MNCs to hone futuristic technical skills in the students.</span></li>
                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Foreign Language Learning via Centre for Foreign Languages to improve International Placements.</span></li>
                                </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__content">
                           
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Faculty</h2>
                                <div class="degree-list-container">
                                  <ul class="degree-list">
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> 300+ faculty with rich industry, academia, and research experience.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> 100+ Doctoral faculty in varied research domains.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> 1000+ Paper Publication in SCI/ SCOPUS indexed Journals.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Govt. funded Sponsored projects worth RS 16.3 Crores.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> 250+ Patents by faculty & students.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Customized Teaching-Learning methodology to make it more Student-Centric and Outcome-Based.</span></li>
                                </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp " data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={faculty} alt="About" width="100%"  />
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={academics} alt="About" width="100%"  />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">
                            
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Academics & Skilling</h2>
                                <div class="degree-list-container">
                                <ul class="degree-list">
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Fully Flexible Choice-Based Credit System (CBCS) with an opportunity for each student to design their own program to get major, minor and honors degrees of their choice.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Competency building on language and communication skills through Cambridge University Empower.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Exclusive department to train students in advanced Technologies & Soft Skills making students Industry-ready.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span>  44 Functional MoUs with MNCs & industries</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Centre of excellences with Industry collaborations</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Competitive coding sessions to imbibe logical thinking and complex problem-solving skills; Hackathons and Mockathons for enhancing design and prototyping skills.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Proctored self-learning for advanced learners and hand-holding care to enhance learning abilities of slow learners.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Credits for skilling in all relevant courses and curriculum aligned with sector-skills council and MNCs.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Inclusion of value-added courses as part of academic mandate to make students industry ready.</span></li>
                                     
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about__content" style={{ marginTop: "10px" }}>
                        <div class="degree-list-container">
                              <ul class="degree-list">
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Labs taken to class, project-based labs and tinkering labs to furnish students with hands-on expertise on contemporary skills.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Innovative learning pedagogies to make learning easy and enthusiastic.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Internationally reputed MOOCs embedded into academics.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Multi-disciplinary specializations to enhance product building abilities in students.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Comprehensive training and support for students to participate in national and international level competitions such as world skills competitions SAE-BAJA & Smart India Hackathon.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Training for competitive exams as per the choice of the students.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Academic credit sharing with study-abroad under Joint Certification programs in collaboration with internationally reputed Universities.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Global certifications from established skill academies and Centre of Excellences on campus.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Industry driven and vetted curriculum.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Exclusive student counseling for career guidance, academic growth, and personal well-being.</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__content">
                           
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Infrastructure</h2>
                                <div class="degree-list-container">
                                 <ul class="degree-list">
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Wi-Fi enabled campus with 3 Gbps Band Width, ICT and Digital Technologies enabled classrooms.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Well-equipped State-of-the-Art Academic Laboratories</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Separate hostels for boys, girls and international students on-campus that serve North-Indian, South-Indian, and international cuisines.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Clean & Green campus.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> 24*7 In-House Clinic facility.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> ATM and Banking facility</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Food Courts & Stationery Stalls</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Arena for Praying (Temple, Mosque & Church within campus)</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={infrastructure} alt="About" width="100%" style={{ marginTop: "100px" }} />
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={amenties} alt="About" width="100%" style={{ marginTop: "100px" }} />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">
                            
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Amenities</h2>
                                <div class="degree-list-container">
                                  <ul class="degree-list">
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Student Clubs and professional society student chapters to enhance the student life skills and holistic development.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Annual national-level techno-management and cultural festivals & national level sports meets.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> NSS, NCC and Extension Activities for imbibing social responsibility among students.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Multi-Cuisine restaurants and 24X7 coffee shops on campus to facilitate the diverse student community.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> High end computers that facilitate students to use computational resources round the clock.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Indoor and Outdoor Sport facilities facilitated with State-of-the-Art indoor stadium.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Separate Gymnasiums for boys and girls.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> In-House Clinic as part of an MoU with Apollo Hospitals with 24X7 resident doctor and nursing staff.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Air-conditioned 500 capacity auditorium, 3000 capacity Amphitheatre with multiple seminar halls of varied capacities.</span></li>
                                     
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about__content" style={{ marginTop: "60px" }}>
                            
                            <div class="degree-list-container">
                               <ul class="degree-list">
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Exclusive centrally air-conditioned Library facility open till 10 PM on all days.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Transport facility from every nook & corner of Kakinada, Rajamahendravaram and their surroundings.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Ragging free environment with staggered timings for freshmen and the seniors.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Students are encouraged to be part of the faculty research leading to international journal paper publications.</span></li>
                                     <li><span class="icon"><img className='pointimage' src={point} /></span><span> Financial assistance for study through tie-up with banks and financial institutions.</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyADITYApart;