import React from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css"
import point from '../../../../assets/images/icons/image.png';
import SideManu from './sidebar_examination';
import { Link } from 'react-router-dom';

import B_Tech from './files/B_tech.pdf';
import M_Tech from './files/M_tech.pdf';
import MBA from './files/mba.pdf';
import MBAI from './files/mbai.pdf';
import MCA from './files/mca.pdf';

import B_Tech_Notification from './files/b_tech/Notification.pdf';
import B_Tech_Internal_Examinations from './files/b_tech/Internal Examinations-II.pdf';
import B_Tech_Examinations_Regular from './files/b_tech/Examinations Regular.pdf';
import Semester_Notification from './files/b_tech/Semester Notification.pdf';

import Dec_2024_25 from './files/m_tech/Dec_2024-25.pdf';
import M_Tech_1 from './files/m_tech/Time Table for M.Tech - I Semester Internal Examinations - II (M.Tech 2024) - JAN 2025.pdf';
import M_Tech_2 from './files/m_tech/Time Table for M.Tech - I Semester End Examinations Regular (M.Tech 2024) - FEB 2025.pdf';




import Mca_Notification from './files/mca/Notification.pdf';
import Mca_Internal_Examinations from './files/mca/Internal Examinations-II .pdf';
import Mca_Examinations_Regular from './files/mca/Examinations Regular.pdf';
import MCASemester_Regular from './files/mca/MCASemester Regular.pdf';



import MBAI_Notification from './files/mbai/Notification.pdf';
import MBAI_Internal_Examinations from './files/mbai/Internal Examinations-II.pdf';
import MBAI_Examinations_Regular from './files/mbai/Examinations Regular.pdf';
import MBAI_Semester_Regular from './files/mbai/MBAI Semester Regular.pdf';




import MBA_Notification from './files/mba/Notification.pdf';
import MBA_Internal_Examinations from './files/mba/Internal Examinations-II.pdf';
import MBA_Examinations_Regular from './files/mba/Examinations Regular.pdf';
import Semester_Regular from './files/mba/Semester Regular.pdf';







const Managementoverview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Examination Centre" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Examination Notifications </b> </center><br /> */}
                                                    <div className="about__area about__area_one p-relative pt---10 pb---120">
                                                        <div className="container">
                                                            <div className="about__content" style={{ marginTop: '-56px' }}>
                                                                <h5><b>B.Tech : </b></h5>
                                                                <div className="campus_sec1 row">
                                                                <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={Semester_Notification} className="more-about" target="blank" style={{ width: "100%" }}> Revised  B.Tech <br /> I Semester  Notification</Link>
                                                                        </div><br />
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={B_Tech_Notification} className="more-about" target="blank" style={{ width: "100%" }}> B.Tech <br /> I Semester Notification</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={B_Tech_Internal_Examinations} className="more-about" target="blank" style={{ width: "100%" }}> B.Tech <br /> I Semester Internal Examinations-II</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div className="campus_sec1 row">
                                                                   <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={B_Tech_Examinations_Regular} className="more-about" target="blank" style={{ width: "100%" }}> B.Tech <br /> I Semester Examinations Regular</Link>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <br />
                                                            
                                                            <div className="about__content" style={{ marginTop: '-56px' }}>
                                                            <h5><b>M.Tech : </b></h5>
                                                                <div className="campus_sec1 row">
                                                                   <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={M_Tech_1} className="more-about" target="blank" style={{ width: "100%" }}> Time Table for M.Tech - I Semester Internal Examinations - II (M.Tech 2024) - JAN 2025 <br /> I Semester Internal Examinations-I</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={M_Tech_2} className="more-about" target="blank" style={{ width: "100%" }}> M.Tech <br /> Time Table for M.Tech - I Semester End Examinations Regular (M.Tech 2024) - FEB 2025</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    <br/>
                                                                <div className="campus_sec1 row">
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={M_Tech} className="more-about" target="blank" style={{ width: "100%" }}> M.Tech <br /> I Semester Internal Examinations-I</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={Dec_2024_25} className="more-about" target="blank" style={{ width: "100%" }}> M.Tech <br /> I Semester End Exam Fee Notification - Dec 2024 </Link>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="about__content" style={{ marginTop: '-56px' }}>
                                                                <h5><b>MBA : </b></h5>
                                                                <div className="campus_sec1 row">
                                                                <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={Semester_Regular} className="more-about" target="blank" style={{ width: "100%" }}> Revised MBA <br /> I Semester Notification</Link>
                                                                        </div><br/>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MBA_Notification} className="more-about" target="blank" style={{ width: "100%" }}> MBA <br /> I Semester Notification</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MBA_Internal_Examinations} className="more-about" target="blank" style={{ width: "100%" }}> MBA <br /> I Semester Internal Examinations-II</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div className="campus_sec1 row">
                                                                   <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MBA_Examinations_Regular} className="more-about" target="blank" style={{ width: "100%" }}> MBA <br /> I Semester Examinations Regular</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <div className="about__content" style={{ marginTop: '-56px' }}>
                                                                <h5><b>MBA(Integrated) : </b></h5>
                                                                <div className="campus_sec1 row">
                                                                <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MBAI_Semester_Regular} className="more-about" target="blank" style={{ width: "100%" }}> Revised MBA(Integrated) <br /> I Semester Notification</Link>
                                                                        </div><br/>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MBAI_Notification} className="more-about" target="blank" style={{ width: "100%" }}> MBA(Integrated) <br /> I Semester Notification</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MBAI_Internal_Examinations} className="more-about" target="blank" style={{ width: "100%" }}> MBA(Integrated) <br /> I Semester Internal Examinations-II</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div className="campus_sec1 row">
                                                                   <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MBAI_Examinations_Regular} className="more-about" target="blank" style={{ width: "100%" }}> MBA(Integrated) <br /> I Semester Examinations Regular</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="about__content" style={{ marginTop: '-56px' }}>
                                                                <h5><b>MCA : </b></h5>
                                                                <div className="campus_sec1 row">
                                                                <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={MCASemester_Regular} className="more-about" target="blank" style={{ width: "100%" }}> Revised MCA <br /> I Semester Notification</Link>
                                                                        </div><br/>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={Mca_Notification} className="more-about" target="blank" style={{ width: "100%" }}> MCA <br /> I Semester Notification</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={Mca_Internal_Examinations} className="more-about" target="blank" style={{ width: "100%" }}> MCA <br /> I Semester Internal Examinations-II</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div className="campus_sec1 row">
                                                                   <div className="col-lg-6">
                                                                        <div className="text-center">
                                                                            <Link to={Mca_Examinations_Regular} className="more-about" target="blank" style={{ width: "100%" }}> MCA <br /> I Semester Examinations Regular</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default Managementoverview;