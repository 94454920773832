import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css";
import SideManu from './sidebar_examination';
// import Linkimage from './images/link.gif';
import Academic_calendar_image from './images/exam.png';


import CE_paper_1 from './model_papers/B_Tech/civil/01 Linear Algebra & Calculus.pdf';
import CE_paper_2 from './model_papers/B_Tech/civil/02 Solid State Physics.pdf';
import CE_paper_3 from './model_papers/B_Tech/civil/03 Basic Electrical & Electronics Engineering.pdf';


import EEE_paper_1 from './model_papers/B_Tech/eee/1.pdf';
import EEE_paper_2 from './model_papers/B_Tech/eee/2.pdf';
import EEE_paper_3 from './model_papers/B_Tech/eee/3.pdf';

import Mech_paper_1 from './model_papers/B_Tech/mech/1.pdf';
import Mech_paper_2 from './model_papers/B_Tech/mech/2.pdf';
import Mech_paper_3 from './model_papers/B_Tech/mech/3.pdf';

import CSE_paper_3 from './model_papers/B_Tech/cse/Applied Chemistry.pdf';
import PT_paper_3 from './model_papers/B_Tech/pt/03_Geology_Sedimentology.pdf';


import AGR_paper_1 from './model_papers/B_Tech/age/1.pdf';
import AGR_paper_2 from './model_papers/B_Tech/age/2.pdf';
import AGR_paper_3 from './model_papers/B_Tech/age/3.pdf';
import AGR_paper_4 from './model_papers/B_Tech/age/4.pdf';







// Define the program data with an array of curriculum names and links
const programData = [
    {
        id: 0,
        title: 'Civil',
        curriculum_name: [
            'Linear algebra and calculus',
            'Solid state physics',
            'Basic electrical and electronics engineering'
        ],
        curriculum: [
            CE_paper_1,
            CE_paper_2,
            CE_paper_3
        ]
    },
    {
        id: 1,
        title: 'EEE',
        curriculum_name: [
            'Linear algebra and calculus',
            'Programming for problem solving using C',
            'Modern physics'
        ],
        curriculum: [
        EEE_paper_1,
        EEE_paper_2,
        EEE_paper_3
        ]
    },
    {
        id: 2,
        title: 'Mechanical',
        curriculum_name: [
            'Linear algebra and calculus',
            'Solid state physics',
            'Basic electrical and electronics engineering'
        ],
        curriculum: [
        Mech_paper_1,
        Mech_paper_2,
        Mech_paper_3
        ]
    },
    {
        id: 3,
        title: 'ECE',
        curriculum_name: [
            'Linear algebra and calculus',
            'Programming for problem solving using C',
            'Modern physics',
            'Basic electrical and electronics engineering'
        ],
        curriculum: [
        EEE_paper_1,
        EEE_paper_2,
        EEE_paper_3,
        Mech_paper_3
        ]
    },
    {
        id: 4,
        title: 'CSE',
        curriculum_name: [
            'Linear algebra and calculus',
            'Programming for problem solving using C',
            'Applied Chemistry',
            'Basic electrical and electronics engineering'
        ],
        curriculum: [
        EEE_paper_1,
        EEE_paper_2,
        CSE_paper_3,
        Mech_paper_3
        ]
    },
    {
        id: 5,
        title: 'IT',
        curriculum_name: [
            'Linear algebra and calculus',
            'Programming for problem solving using C',
            'Modern physics'
            
        ],
        curriculum: [
        EEE_paper_1,
        EEE_paper_2,
        EEE_paper_3
        
        ]
    },
    {
        id: 6,
        title: 'AIML',
        curriculum_name: [
            'Linear algebra and calculus',
            'Programming for problem solving using C',
            'Applied Chemistry'
            
        ],
        curriculum: [
        EEE_paper_1,
        EEE_paper_2,
        CSE_paper_3
        
        ]
    },
    {
        id: 7,
        title: 'Data Science',
        curriculum_name: [
            'Linear algebra and calculus',
            'Programming for problem solving using C',
            'Modern physics'
            
        ],
        curriculum: [
        EEE_paper_1,
        EEE_paper_2,
        EEE_paper_3
        
        ]
    },
    {
        id: 8,
        title: 'PT',
        curriculum_name: [
            'Linear algebra and calculus',
            'Solid state physics',
            'Geology & sedimentology for petroleum engineers'
            
        ],
        curriculum: [
        EEE_paper_1,
        Mech_paper_2,
        PT_paper_3
        
        ]
    },
    {
        id: 9,
        title: 'Mining',
        curriculum_name: [
            'Linear algebra and calculus',
            'Solid state physics',
            'Basic electrical and electronics engineering'
            
        ],
        curriculum: [
        EEE_paper_1,
        Mech_paper_2,
        Mech_paper_3
        
        ]
    },
    {
        id: 10,
        title: 'Agricultural',
        curriculum_name: [
            'Crop production and protection technologies',
            'Introduction to agricultural engineering',
            'Basic electrical gadgets and instruments',
            'Agricultural informatics'
        ],
        curriculum: [
        AGR_paper_1,
        AGR_paper_2,
        AGR_paper_3,
        AGR_paper_4
        ]
    },
    
];

const Curriculum = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Initialize to make the first item open by default

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Examination Centre" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research" id="">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div>
                                                        {programData.map(({ id, title, curriculum, curriculum_name}) => {
                                                            console.log(`Rendering Program ID: ${id}`);
                                                            console.log("Curriculum Links:", curriculum);
                                                            console.log("Curriculum Names:", curriculum_name);

                                                            return (
                                                                <React.Fragment key={id}>
                                                                    <button
                                                                        className={`accordion ${activeIndex === id ? 'active' : ''}`}
                                                                        onClick={() => toggleAccordion(id)}
                                                                    >
                                                                        <img src={Academic_calendar_image} style={{ height: '40px' }} alt={title} />
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none', marginBottom: '14px' }}>
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>I Semester</th>
                                                                                    <th style={{ textAlign: 'center' }}>B.Tech-2024</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {curriculum.map((curr, index) => {
                                                                                    if (!curr || !curriculum_name[index]) {
                                                                                        console.error(`Missing curriculum data at index ${index} for program ${title}`);
                                                                                        return null; // Skip rendering if data is missing
                                                                                    }
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{curriculum_name[index]}</td>
                                                                                            <td style={{ textAlign: 'center' }}>
                                                                                                <a href={curr} target="_blank" rel="noopener noreferrer" style={{ color: "#000" }} className='namehover'>
                                                                                                    View
                                                                                                </a>
                                                                                                {/* <img src={Linkimage} style={{ height: '25px', marginLeft: '5px' }} alt="link icon" /> */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                        
                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />
        </>
    );
}

export default Curriculum;
