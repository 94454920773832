import React, { useState, useEffect } from "react";
import axios from 'axios';
import Header from "../../../components/Header";
import FooterTwo from "../../../components/Footer/FooterTwo";
import StudyBreadcrumb from "../../../components/Breadcrumb/international";
import ScrollToTop from "../../../components/ScrollTop";
import "../../../components/Styles/pragram.css";
import SideManu from "./sidebar_opportunities";
import 'react-toastify/dist/ReactToastify.css';
import Placement_statistics_banner from './images/placement_statistics.jpg';

const backendUrl = process.env.REACT_APP_DATABASEURL;

const PlacementStatistics = () => {
    const [selectedYear, setSelectedYear] = useState("2024-2025"); // Default selected year
    const [data, setData] = useState([]); // Data to display
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    // Fetch data based on selected year
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await axios.get(`${backendUrl}api/placement_statistics/${selectedYear}`);
                console.log("API Response:", response);

                // Set data only if the response is valid
                if (response.status === 200 && response.data) {
                    setData(response.data.Statistics_Details);
                } else {
                    throw new Error("Unexpected API response format.");
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setError(err.response?.data?.message || err.message || "Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedYear, backendUrl]);


    return (
        <>
            <Header parentMenu="page" menuCategoryEnable="enable" />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Placement Statistics" />
                    {/* <img src={Placement_statistics_banner} /> */}

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research" id="">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div
                                                className="col-md-8 col-sm-8 col-12"
                                                style={{ backgroundColor: "#f8f9fa" }}
                                            >
                                                <br />
                                                <div className="container">
                                                    {/* Year Selector */}
                                                    <img src={Placement_statistics_banner} style={{width:'100%'}}/>
                                                    <center>
                                                        <div className="col-md-4 col-sm-4 col-12">
                                                            <div className="login-right-form">
                                                                <form>
                                                                    <div className="row">
                                                                        <b htmlFor="Year" className="select-label" style={{ color: '#d3143f' }}>Select Year </b>
                                                                        <select
                                                                            name="Year"
                                                                            id="Year"
                                                                            value={selectedYear}
                                                                            onChange={(e) => setSelectedYear(e.target.value)}
                                                                            className="year-select"
                                                                        >
                                                                            <option value="2021-2022">2021-2022</option>
                                                                            <option value="2022-2023">2022-2023</option>
                                                                            <option value="2023-2024">2023-2024</option>
                                                                            <option value="2024-2025">2024-2025 (Still Counting)</option>
                                                                        </select>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </center>
                                                </div>
                                                <br />

                                                {loading && <p>Loading...</p>}
                                                {error && <p style={{ color: "red" }}>{error}</p>}
                                                {!loading && !error && data.length > 0 && (
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr style={{ backgroundColor: '#00428A', color: '#fff' }}>
                                                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>S.No</th>
                                                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Name of the Company Visited</th>
                                                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>No. of Students Placed</th>
                                                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Package</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((item, index) => (
                                                                    <tr key={item.id}>
                                                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                                                                        <td style={{ verticalAlign: 'middle' }}>{item.company_name}</td>
                                                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.placed}</td>
                                                                        <td style={{ verticalAlign: 'middle' }}>{item.package}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr style={{ backgroundColor: '#f1f1f1', fontWeight: 'bold' }}>
                                                                    <td colSpan="2" style={{ textAlign: 'center', verticalAlign: 'middle' }}>Total Students Placed</td>
                                                                    <td colSpan="2" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                        {data.reduce((total, item) => total + item.placed, 0)}
                                                                    </td>
                                                                    {/* <td> {data.length > 0 ? `${Math.max(...data.map(item => item.package))} LPA` : 'N/A'} </td> */}
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                )}
                                                {!loading && !error && data.length === 0 && (
                                                    <p>No data available for the selected year.</p>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Scroll to Top */}
                    <ScrollToTop />
                </div>
            </div>

            <FooterTwo />
        </>
    );
};

export default PlacementStatistics;
