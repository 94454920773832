import React, { useState } from "react";
import color_Video from './video/colors.mp4';


const Apply = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleApplyClick = () => {
        setIsPopupOpen(true); // Show the popup
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false); // Close the popup
    };

    return (
        <div className="container">
            <div className="row">
                <button type="button" onClick={handleApplyClick}  style={{padding:'2px',borderRadius: '5px',fontWeight: '600'}}>
                &nbsp;Colours 2025&nbsp;
                </button>
            </div>

            {/* Popup */}
            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="close-button" onClick={handleClosePopup}>
                            &times;
                        </button>
                        <video
                            width="100%"
                            height="auto"
                            controls
                            autoPlay
                        >
                            <source
                                src={color_Video} // Replace with your video URL
                                type="video/mp4"
                            />

                        </video>
                    </div>
                </div>
            )}

            {/* Popup Styles */}
            <style jsx>{`
        .popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .popup-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          position: relative;
          max-width: 50%;
          max-height: 60%;
          overflow: hidden;
        }

        .close-button {
            position: absolute;
            top: -29px;
            right: -13px;
            background: transparent;
            border: none;
            font-size: 42px;
            cursor: pointer;
        }

        .close-button:hover {
          color: red;
        }
      `}</style>
        </div>
    );
};

export default Apply;

