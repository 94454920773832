import React from 'react';
import ScrollToTop from '../../components/ScrollTop';
import "../../components/Styles/pragram.css"
const Fees = () => {
    return (
        <>
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                           <center><b  class="text-center" data-wow-duration="0.3s" style={{ color: "#D2093C", fontSize: "25px",textAlign:'center' }}>FEE STRUCTURE FOR AY 2025-26 <br /></b></center>
                            <div class="row m-0">
                                <div class="col-md-12  col-sm-12 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                    <div class="container">
                                        <br />
                                        <div class="about__content">
                                            <div class="table-responsive table-bordered table-striped ">
                                                <table class="table responsive">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}>UG PROGRAM</th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "15%", textAlign: "center" }}>Admission fee <br /> (one time)</th> */}
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}>Tuition fee per year in INR *<br /> </th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "20%", textAlign: "center" }}>Tuition fee * <br />  Admission through (AP EAPCET) </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>B.Tech - Computer Science & Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>2,75,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Artificial Intelligence & Machine Learning</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>2,25,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Information Technology</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,75,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Computer Science & Engineering (Data Science)</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,75,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Electronics & Communication Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,75,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Electrical & Electronics Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,00,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Civil Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>85,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                       <tr>
                                                            <td>B.Tech - Mechanical Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>85,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Petroleum Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>85,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr> <tr>
                                                            <td>B.Tech - Agricultural Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>85,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Mining Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>85,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>BBA</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>BBA - Digital Marketing</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>BBA - Business Analytics</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td> B.Pharmacy</td>
                                                            <td className='center'>2,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td> Pharma-D</td>
                                                            <td className='center'>2,50,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>B.Sc - Forensic Science</td>
                                                           <td className='center'>90,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>B.Sc - Cyber Forensics</td>
                                                           <td className='center'>90,000</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <b style={{ color: "#D2093C", fontSize: "18px" }}>* Tuition Fee for International B.Tech Program changes during the course of study at Foreign University. The fee will be as per the Foreign Unviersity norms.</b>
                                            <br />   <br />  <br /> */}
                                             <b style={{ color: "#D2093C", fontSize: "18px" }}>* Note</b>
                                             <br/>
                                             <span>1. Admission Fee : ₹ 15,000 (Non-Refundable)</span><br/>
                                             <span>2. Aditya Scholastic Aptitude Test(ASAT) Fee  : ₹ 500 per attempt (Maximum 2 attempts)</span>
                                             <br />  
                                            <div class="table-responsive table-bordered table-striped ">
                                                <table className='table responsive`'>

                                                    <thead>
                                                        <tr>
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}> PG PROGRAM</th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "15%", textAlign: "center" }}>Admission fee <br /> (one time)</th> */}
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}>Tuition fee per year in INR*<br /> </th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "20%", textAlign: "center" }}>Tuition fee * <br />  Admission through <br />(AP PGECET/ICET) </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        
                                                    <tr>
                                                            <td>M.Tech - VLSI Design</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Energy Science & Technology</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Computer Science & Engineering</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Power Electronics and Drives</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Structural Engineering</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - CSE (AI & ML)</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Master of Computer Applications</td>
                                                            <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Master of Business Administration</td>
                                                            <td className='center'>1,00,000</td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Master of Business Administration (Integrated)</td>
                                                            <td className='center'>1,00,000</td>
                                                        </tr>
                                                       
                                                        <tr>
                                                            <td>M.Pharmacy - Pharmacology</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Pharmacy - Pharmaceutics</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Pharmacy - Pharma Chemistry</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Pharmacy - Pharmacognosy</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Sc - Cyber Security</td>
                                                            <td className='center'>85,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Sc - Forensic Science</td>
                                                            <td className='center'>85,000</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <b style={{ color: "#D2093C", fontSize: "18px" }}>* Note</b>
                                             <br/>
                                             <span>1. Admission Fee : ₹ 15,000 (Non-Refundable)</span><br/>
                                             <span>2. Aditya Scholastic Aptitude Test(ASAT) Fee  : ₹ 500 per attempt (Maximum 2 attempts)</span>
                                             <br /> 
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>

                            <br></br>
                            <div style={{display:'none'}}>
                           <center><b  class="text-center" data-wow-duration="0.3s" style={{ color: "#D2093C", fontSize: "25px",textAlign:'center' }}>FEE STRUCTURE FOR AY 2024-25 <br /> (for all States' Except AP & TS Students)</b></center>
                            <div class="row m-0">
                                <div class="col-md-12  col-sm-12 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                    <div class="container">
                                        <br />
                                        <div class="about__content">
                                            <div class="table-responsive table-bordered table-striped ">
                                                <table class="table responsive">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}>UG PROGRAM</th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "15%", textAlign: "center" }}>Admission fee <br /> (one time)</th> */}
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}>Tuition fee *<br /> (Direct Admission at Aditya University)</th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "20%", textAlign: "center" }}>Tuition fee * <br />  Admission through (AP EAPCET) </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>B.Tech - Computer Science & Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>2,75,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Information Technology</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,50,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Artificial Intelligence & Machine Learning</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,50,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Computer Science & Engineering (Data Science)</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,50,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Electronics & Communication Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,30,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Civil Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,00,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Electrical & Electroncis Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,00,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Mechanical Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,00,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Petroleum Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,00,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr> <tr>
                                                            <td>B.Tech - Agricultural Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,00,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>B.Tech - Mining Engineering</td>
                                                            {/* <td className='center'>15,000</td> */}
                                                            <td className='center'>1,00,000</td>
                                                            {/* <td className='center'>As per APHERMC guidelines</td> */}
                                                        </tr>
                                                        

                                                    </tbody>
                                                </table>
                                            </div>
                                            <b style={{ color: "#D2093C", fontSize: "18px" }}>* Tuition Fee for International B.Tech Program changes during the course of study at Foreign University. The fee will be as per the Foreign Unviersity norms.</b>
                                            <br />   <br />  <br />
                                            <div class="table-responsive table-bordered table-striped ">
                                                <table className='table responsive`'>

                                                    <thead>
                                                        <tr>
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}> PG PROGRAM</th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "15%", textAlign: "center" }}>Admission fee <br /> (one time)</th> */}
                                                            <th style={{ backgroundColor: "#00428A",color:'#fff', width: "50%", textAlign: "center" }}>Tuition fee *<br /> (Direct Admission at  Aditya University                                                          
                                                            )</th>
                                                            {/* <th style={{ backgroundColor: "#00428A",color:'#fff', width: "20%", textAlign: "center" }}>Tuition fee * <br />  Admission through <br/>(AP PGECET/ICET) </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>M.Tech - VLSI Design</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Energy Science & Technology</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Computer Science & Engineering</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Power Electronics and Drives</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - Structural Engineering</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Tech - CSE (AI & ML)</td>
                                                            <td className='center'>75,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Master of Computer Applications</td>
                                                            <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Master of Business Administration</td>
                                                            <td className='center'>1,00,000</td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Master of Business Administration (Integrated)</td>
                                                            <td className='center'>1,00,000</td>
                                                        </tr>
                                                       
                                                        <tr>
                                                            <td>M.Pharmacy - Pharmacology</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Pharmacy - Pharmaceutics</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Pharmacy - Pharma Chemistry</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Pharmacy - Pharmacognosy</td>
                                                           <td className='center'>1,00,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Sc - Cyber Forensics</td>
                                                            <td className='center'>85,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>M.Sc - Forensic Science</td>
                                                            <td className='center'>85,000</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <b style={{ color: "#D2093C", fontSize: "18px" }}>* Tuition Fee for International MBA Program changes during the course of study at Foreign University. The fee will be as per the Foreign Unviersity norms.</b>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
        </>
    );
}

export default Fees;