// import React from 'react';

// const ICACTEA = () => {
//   return (
//     <div className="container-fluid" style={{ padding: 0, margin: 0 ,backgroundColor:'#000'}}>
//       <iframe
//         src="https://aec.edu.in/ICACTEA2025/"
//         style={{
//           width: '100%',
//           height: '99vh', 
//           border: 'none', 
//         }}
//         title="ICACTEA 2025"
//       />
//     </div>
//   );
// }

// export default ICACTEA;


import React, { useEffect } from 'react';



const ICACTEA = () => {
  useEffect(() => {
    window.location.href = 'https://aec.edu.in/ICACTEA2025/';
  }, [])
  return (
  <></>    
  )
}

export default ICACTEA;



