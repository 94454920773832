import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/programsoffered';
import ScrollToTop from '../../../components/ScrollTop';
import "../../../components/Styles/pragram.css"
import SideManu from './sidebar_programs';
import coming_soon from '../../../assets/images/icons/coming_soon.png';
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import Brochure from "../../../components/Popup/Brochure/brochure";
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';



const PGPrograms = () => {
    let tab1 = "B.Tech",
        tab2 = "B.Sc",
        tab3 = "BBA",
        tab4 = "Pharmacy"

    const tabStyle = 'nav nav-tabs';
    const [selectedIndex, setSelectedIndex] = useState(0);


    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div className="back__course__page_grid react-courses__single-page pb---16 ">
                <div class="react-wrapper-inner">

                    <StudyBreadcrumb pageTitle="UG Programs" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">

                                                    <Tabs selectedIndex={selectedIndex} onSelect={index => setSelectedIndex(index)}>
                                                        <div className="course-single-tab">
                                                            <TabList className={tabStyle}>
                                                                <Tab className='col-md-3'><button>{tab1}</button></Tab>
                                                                <Tab className='col-md-3'><button>{tab2}</button></Tab>
                                                                <Tab className='col-md-3'><button>{tab3}</button></Tab>
                                                                <Tab className='col-md-3'><button>{tab4}</button></Tab>
                                                            </TabList>

                                                            <div>
                                                                <TabPanel>
                                                                    <div class="container">
                                                                        <br />
                                                                        <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Programs Offered with Foreign Universities Collaboration</b>
                                                                        <br />
                                                                        <div class="about__content">

                                                                            <span class="name"> B. Tech Programs in collaboration with RWTH Aachen University, Germany <b><br/>(QS Ranking:99 , THE Ranking :90)</b></span><br />

                                                                            <span class="name">Students can opt for this Joint Degree Certification where stipulated credits are to be earned from Aditya University and RWTH Aachen University, Germany. </span><br />
                                                                            <span class="name"> The fee structure will be as per the respective university guidelines during the students' course of study.</span><br /><br />

                                                                            <div className="campus_sec1 row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="text-center">
                                                                                        {/* <Link to="" className="more-about" target="blank" style={{ width: "100%" }}>  Brochure <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link> */}
                                                                                        <Brochure />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="text-center">
                                                                                        <Link to="https://apply.adityauniversity.in/" style={{ width: "100%" }} className="more-about" target="blank"> Apply Now !  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>B.Tech Programs Offered </b> <br />
                                                                            <div class="degree-list-container">
                                                                                <ul class="degree-list">
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Agricultural Engineering
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Civil Engineering  <span className='animated-text'> NBA Tier- I Accredited</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Civil Engineering -  <span className='heading_side'> In Partnership with L & T </span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Computer Science and Engineering -  <span className='animated-text'> NBA Tier- I Accredited</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Computer Science and Engineering -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Artificial Intelligence & Machine Learning
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Artificial Intelligence & Machine Learning -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - (Computer Science and Engineering) - Data Science
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - (Computer Science and Engineering) - Data Science -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Electronics and Communication Engineering <span className='animated-text'> NBA Tier- I Accredited</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Electronics and Communication Engineering  -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Electrical and Electronics Engineering  <span className='animated-text'> NBA Tier- I Accredited</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Electrical and Electronics Engineering  -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Information Technology <span className='animated-text'> NBA Tier- I Accredited</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Information Technology  -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Mechanical Engineering <span className='animated-text'> NBA Tier- I Accredited</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Mechanical Engineering  -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Mining Engineering
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Mining Engineering  -  <span className='heading_side'> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft</span>
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Petroleum Technology
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            B.Tech. - Petroleum Technology  -  <span className='heading_side'> In Partnership with L & T, CISCO, Red Hat, Oracle, AWS, Google & Microsoft </span>
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>



                                                                            <b style={{ color: "#D2093C", fontSize: "20px" }}>Eligibility Criteria for B.Tech Admission</b><br />
                                                                            <p className='name'>
                                                                                <div class="degree-list-container">
                                                                                    <ul class="degree-list">
                                                                                        <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                            <span>
                                                                                                Applicants must be at least 16 years old by December 31st of the admission year, with no upper age limit.
                                                                                            </span>
                                                                                        </li>
                                                                                        <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                            <span>
                                                                                                Candidates have to be successful in ASAT (Aditya Scholastic Aptitude Test)/ JEE-Main/ State-Level Engineering Entrance Exams across India including EAPCET and Merit in Sports Activities.
                                                                                            </span>
                                                                                        </li>
                                                                                        <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                            <span>
                                                                                                Candidates seeking UG Engineering admission must have completed or be appearing in 2024 for one of the specified qualifying examinations. Additionally, a minimum of 60% or equivalent CGPA is required for provisional candidature.
                                                                                            </span>
                                                                                        </li>
                                                                                        <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                            <span>
                                                                                                Final examination of 10+2 system by State Boards, CBSE, or CISCE with core subjects as given below.
                                                                                            </span>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </p>
                                                                            <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                                                <div class="degree-list-container">
                                                                                    <ul class="degree-list">
                                                                                        <li><span class="icon"><img className='pointimage' src={point1} /></span>
                                                                                            <span>
                                                                                                Students with Physics, Mathematics & Chemistry/ Computer Science/ Electronics/ Information Technology/ Biology/ Informatics Practices/ Biotechnology/Technical Vocational Subject/ Agriculture/ Engineering Graphics/ Business Studies/ Entrepreneurship are eligible for B.Tech Program in Electrical & Electronics Engineering, Electronics & Communications Engineering, Computer Science & Engineering, Information Technology, Artificial Intelligence & Machine Learning, Data Science.
                                                                                            </span>
                                                                                        </li>
                                                                                        <li><span class="icon"><img className='pointimage' src={point1} /></span>
                                                                                            <span>
                                                                                                Students with Physics, Chemistry and Mathematics (PCM) are eligible for B.Tech Programs in Civil Engineering, Mechanical Engineering, Petroleum Technology & Mining Engineering
                                                                                            </span>
                                                                                        </li>
                                                                                        <li><span class="icon"><img className='pointimage' src={point1} /></span>
                                                                                            <span>
                                                                                                Applicants who completed Class 12 (or equivalent) exams outside India or from a non-specified board must provide an AIU certificate confirming equivalence to Class 12, with grades/CGPA converted to percentage.
                                                                                            </span>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </p>
                                                                            <div class="degree-list-container">
                                                                                <ul class="degree-list">
                                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span>
                                                                                        <span>
                                                                                            Applicants who completed Class 12 (or equivalent) exams outside India or from a non-specified board must provide an AIU certificate confirming equivalence to Class 12, with grades/CGPA converted to percentage.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            <div className="campus_sec1 row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="text-center">
                                                                                        <Link to="/fees" className="more-about" style={{ width: "100%" }}> Fees </Link>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="text-center">
                                                                                        <Link to="/scholarship" className="more-about" style={{ width: "100%" }}> Scholarships</Link>
                                                                                        {/* <Link to="/scholarship" className="more-about" style={{ width: "100%" }}> Scholarships <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="text-center">
                                                                                        <Link to="https://apply.adityauniversity.in/" className="more-about" target="blank" style={{ width: "100%" }}> Apply Now </Link>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                </TabPanel>
                                                                <TabPanel>
                                                                <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Programs Offered :</b> <br />
                                                                    <div class="degree-list-container">
                                                                        <ul class="degree-list">
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  B.Sc - Forensic Science </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  B.Sc - Cyber Forensics </span></li>
                                                                        </ul>
                                                                    </div>
                                                                 </TabPanel>
                                                                <TabPanel>
                                                                <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Programs Offered :</b> <br />
                                                                    <div class="degree-list-container">
                                                                        <ul class="degree-list">
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  BBA </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> BBA - Digital Marketing </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> BBA - Business Analytics </span></li>

                                                                        </ul>
                                                                    </div>

                                                                </TabPanel>
                                                                <TabPanel>
                                                                <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Programs Offered :</b> <br />
                                                                    <div class="degree-list-container">
                                                                        <ul class="degree-list">
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Bachelor of Pharmacy (B.Pharm) </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> Pharma - D </span></li>
                                                                        </ul>
                                                                    </div>

                                                                </TabPanel>
                                                            </div>
                                                        </div>
                                                    </Tabs>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default PGPrograms;