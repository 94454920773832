import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/iqac';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';

import SideManu from './sidebar_msg';
import Chairman_img from './images/chairman.jpg';





const accreditations = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Chancellor's Message" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div className="col-lg-8" style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '20px' }}>
                                                <div className="about__content about__content1">
                                                                           <center><img src={Chairman_img} alt="About" /></center>
                                                    <b style={{ fontSize: '18px', color: "#D2093C" }}>Dreams alone won't bring success; it takes sweat, determination, and action
                                                    </b>
                                                    <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px'}}>
                                                        Motivated by a vision of national development through education, I founded Aditya University to provide top-tier education while nurturing future leaders. Inspired by Charles Darwin's principle of adaptability, we've overcome challenges to create an environment where students thrive. At Aditya University, we pride ourselves on offering practical, industry-focused education that attracts the brightest minds. Our alumni's success in top companies worldwide is a testament to the dedication of our faculty, who instill in each student values of dedication, sincerity, and loyalty. Today. Aditya University stands as a testament to our commitment to nurturing efficient, confident, and globally minded professionals. With initiatives like the Global Business Incubation Centre and Technical Skill Development Institute, we equip our students to lead with innovation and foresight.</p>
                                                    <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                                        Our dream and commitment lie in shaping every student's journey at Aditya University into a resounding success story.
                                                    </p>
                                                    <b style={{ float: 'right' }}>
                                                        Best regards,<br />
                                                        Dr. N. Sesha Reddy<br />
                                                        Chancellor<br />
                                                        Aditya university
                                                    </b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default accreditations;