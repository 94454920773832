
import { Link } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/Leadership';
import ScrollToTop from '../../components/ScrollTop';

// Image
import Pro_VC from './images/Pro-VC.JPG';


const Deputy_Chancellor = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Pro Vice-Chancellor (Academics)" />
                    <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
                        <div class="container">
                            <div class="row">
                                {/* <div className="col-lg-4">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={Deputy_Chancellor_image} alt="About" />
                                    </div>
                                </div> */}
                                <div className="offset-1 col-lg-10" style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '20px' }}>
                                    <div className="about__content about__content1">
                                        <center><img src={Pro_VC} alt="About" style={{width:'55%'}} /></center>
                                        <br/>

                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                            At Aditya University, academic excellence lies at the heart of our mission. As the Pro Vice-Chancellor (Academics), I am proud to lead a dynamic and progressive academic environment that integrates innovation, inclusivity and quality in education.
                                        
                                            Our programs are designed to inspire intellectual curiosity, foster critical thinking, and nurture the holistic development of our students. By emphasizing cutting-edge curriculum, experiential learning, and industry-driven skills, we ensure that our graduates are equipped to excel in an ever-evolving global landscape.
                                       
                                            We take pride in fostering interdisciplinary collaboration and building bridges between academia, industry and society. Through state-of-the-art facilities and a dedicated team of faculty, we enable students to turn challenges into opportunities, blending traditional knowledge with emerging technologies such as AI, robotics and green solutions 

                                            Aditya University is committed to shaping the leaders of tomorrow, emphasizing academic rigor alongside ethical and social responsibility.
                                        </p>
                                      
                                            
                                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                                 Our goal is not just to educate but to empower students to become agents of meaningful change in their communities and beyond.
                                            </p>
                                        <b style={{ float: 'right' }}>
                                            Best regards,<br />
                                            Dr. S. Rama Sree<br />
                                            Pro Vice-Chancellor (Academics) <br />
                                            Aditya university
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Deputy_Chancellor;