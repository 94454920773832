import React from 'react';
import Slider from 'react-slick';
import '../../components/Styles/pragram.css';
import SectionTitle from '../../components/SectionTitle';
import image1 from './images/milestone_image/winner.jpg';
import image2 from './images/milestone_image/naac.jpg';
import image3 from './images/milestone_image/nirf.jpg';
import image4 from './images/milestone_image/NBA.jpg';
import image5 from './images/milestone_image/Top.jpg';






const DiplomaPrograms = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <div className="container-fluid">
                <div className="react-wrapper-inner">
                    <div className="high_quality-section pt---35 pb---35">
                        <center>
                        <div className="container">
                            <div className="col-md-12">
                            {/* <SectionTitle Title="Proud Moments of Adityans" /> */}
                                <div className="scrolling-container">
                                    <Slider {...settings}>
                                        <div>
                                           <img src={image1} alt="About"/>
                                        </div>
                                       
                                        <div>
                                           <img src={image2} alt="About" />
                                        </div>
                                        <div>
                                          <img src={image3} alt="About" />
                                        </div>
                                        <div>
                                          <img src={image4} alt="About" />
                                        </div>
                                        <div>
                                          <img src={image5} alt="About" />
                                        </div>
                                    </Slider>
                                </div>
                              </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>

            {/* <FooterTwo /> */}
        </>
    );
}

export default DiplomaPrograms;
