import React from 'react';
import Slider from 'react-slick';
import '../../../components/Styles/pragram.css';
import SectionTitle from '../../../components/SectionTitle';
import image1 from './images/placed_images/Stastics.jpg';
import image2 from './images/placed_images/35.36_2.jpg';
import image3 from './images/placed_images/35.36_1.jpg';
import image4 from './images/placed_images/34.95.jpg';
import image5 from './images/placed_images/34.31.jpg';
import image6 from './images/placed_images/31.12_4.jpg';
import image7 from './images/placed_images/31.12_3.jpg';
import image8 from './images/placed_images/31.12_2.jpg';
import image9 from './images/placed_images/31.12_1.jpg';






const DiplomaPrograms = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <div className="container-fluid">
                <div className="react-wrapper-inner">
                    <div className="high_quality-section pt---30 pb---35">
                        <div className="scrolling-container">
                            {/* <Slider {...settings}>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/ocfXp3nY8RU" allowFullScreen title="Video 1" ></iframe>
                                </div>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/O9B8MEUW7cM" allowFullScreen title="Video 2"></iframe>
                                </div>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/2Cd13B4d9rk" allowFullScreen title="Video 3"></iframe>
                                </div>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/NbmJCjQo7-Y" allowFullScreen title="Video 4"></iframe>
                                </div>
                            </Slider> */}
                            <Slider {...settings}>
                                <div>
                                    <img src={image1} alt="About" />
                                </div>

                                <div>
                                    <img src={image2} alt="About" />
                                </div>
                                <div>
                                    <img src={image3} alt="About" />
                                </div>
                                <div>
                                    <img src={image4} alt="About" />
                                </div>
                                <div>
                                    <img src={image5} alt="About" />
                                </div>
                                <div>
                                    <img src={image6} alt="About" />
                                </div>
                                <div>
                                    <img src={image7} alt="About" />
                                </div>
                                <div>
                                    <img src={image8} alt="About" />
                                </div>
                                <div>
                                    <img src={image9} alt="About" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DiplomaPrograms;
