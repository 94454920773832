import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css";
import SideManu from './sidebar_examination';
// import Linkimage from './images/link.gif';
import Academic_calendar_image from './images/exam.png';


import CE_paper_1 from './model_papers/M_Tech/civil/Theory of Elasticity.pdf';
import CE_paper_2 from './model_papers/M_Tech/civil/STRUCTURAL HEALTH MONITORING.pdf';
import CE_paper_3 from './model_papers/M_Tech/civil/DESIGN OF PRE-STRESSED CONCRETE STRUCTURES.pdf';
import CE_paper_4 from './model_papers/M_Tech/civil/ADVANCED MATHEMATICAL METHODS.pdf';
import CE_paper_5 from './model_papers/M_Tech/civil/ADVANCED CONCRETE TECHNOLOGY.pdf';

import Mech_paper_1 from './model_papers/M_Tech/mech/ALTERNATIVE FUELS.pdf';
import Mech_paper_2 from './model_papers/M_Tech/mech/FUELS COMBUSTION AND EMISSION CONTROL.pdf';
import Mech_paper_3 from './model_papers/M_Tech/mech/ENERGY SYSTEMS AND MANAGEMENT.pdf';
import Mech_paper_4 from './model_papers/M_Tech/mech/ENERGY CONSERVATION AND WASTE HEAT RECOVERY.pdf';
import Mech_paper_5 from './model_papers/M_Tech/mech/ADVANCED THERMODYNAMICS.pdf';
import Mech_paper_6 from './model_papers/M_Tech/mech/ADVANCED FLUID MECHANICS.pdf';



import EEE_paper_1 from './model_papers/M_Tech/eee/RENEWABLE ENERGY TECHNOLOGIES.pdf';
import EEE_paper_2 from './model_papers/M_Tech/eee/REACTIVE POWER COMPENSATION AND MANAGEMENT.pdf';
import EEE_paper_3 from './model_papers/M_Tech/eee/POWER ELECTRONIC CONVERTERS.pdf';
import EEE_paper_4 from './model_papers/M_Tech/eee/POWER CONVERTER TECHNOLOGIES.pdf';
import EEE_paper_5 from './model_papers/M_Tech/eee/ELECTRICAL MACHINE MODELING AND ANALYSIS.pdf';
import EEE_paper_6 from './model_papers/M_Tech/eee/ELECTRICAL DRIVES.pdf';




import CSE_paper_1 from './model_papers/M_Tech/cse/OPERATING SYSTEMS THEORY AND PRACTICE.pdf';
import CSE_paper_2 from './model_papers/M_Tech/cse/MODERN COMPUTER NETWORKS.pdf';
import CSE_paper_3 from './model_papers/M_Tech/cse/MATHEMATICS FOR COMPUTER SCIENCE.pdf';
import CSE_paper_4 from './model_papers/M_Tech/cse/INTERNET OF THINGS.pdf';
import CSE_paper_5 from './model_papers/M_Tech/cse/DATA STRUCTURES AND ALGORITHMS.pdf';
import CSE_paper_6 from './model_papers/M_Tech/cse/DATA SCIENCE FUNDAMENTALS.pdf';
import CSE_paper_7 from './model_papers/M_Tech/cse/AI FOR CYBER SECURITY.pdf';




import Ece_paper_1 from './model_papers/M_Tech/ece/VERIFICATION USING SYSTEM VERILOG AND UVM.pdf';
import Ece_paper_2 from './model_papers/M_Tech/ece/SCRIPTING LANGUAGES.pdf';
import Ece_paper_3 from './model_papers/M_Tech/ece/NANOELECTRONICS.pdf';
import Ece_paper_4 from './model_papers/M_Tech/ece/LOW POWER VLSI CIRCUIT DESIGN.pdf';
import Ece_paper_5 from './model_papers/M_Tech/ece/FPGA BASED SYSTEM DESIGN.pdf';
import Ece_paper_6 from './model_papers/M_Tech/ece/DIGITAL IC DESIGN.pdf';
import Ece_paper_7 from './model_papers/M_Tech/ece/ANALOG IC DESIGN.pdf';









// Define the program data with an array of curriculum names and links
const programData = [
    {
        id: 0,
        title: 'Civil',
        curriculum_name: [
            'Theory of Elasticity',
            'Structural Health Monitoring',
            'Design Of Pre-stressed Concrete Structures',
            'Advanced Mathematical Methods',
            'Advanced Concrete Technology'
        ],
        curriculum: [
            CE_paper_1,
            CE_paper_2,
            CE_paper_3,
            CE_paper_4,
            CE_paper_5
        ]
    },
    {
        id: 1,
        title: 'EEE',
        curriculum_name: [
            'Renewable Energy Technologies',
            'Reactive Power Compensation And Management',
            'Power Electronic Converters',
            'Power Converter Technologies',
            'Electrical Machine Modeling And Analysis',
            'Electrical Drives'
        ],
        curriculum: [
            EEE_paper_1,
            EEE_paper_2,
            EEE_paper_3,
            EEE_paper_4,
            EEE_paper_5,
            EEE_paper_6

        ]
    },
    {
        id: 2,
        title: 'Mechanical',
        curriculum_name: [
            'Alternative Fuels',
            'Fuels Combustion And Emission Control',
            'Energy Systems And Management',
            'Energy Conservation And Waste Heat Recovery',
            'Advanced Thermodynamics',
            'Advanced Fluid Mechanics'
        ],
        curriculum: [
            Mech_paper_1,
            Mech_paper_2,
            Mech_paper_3,
            Mech_paper_4,
            Mech_paper_5,
            Mech_paper_6,
        ]
    },
    {
        id: 3,
        title: 'ECE',
        curriculum_name: [
            'Verification Using System Verilog And UVM',
            'Scripting Languages',
            'Nanoelectronics',
            'Low Power Vlsi Circuit Design',
            'Fpga Based System Design',
            'Digital Ic Design',
            'Analog Ic Design'
        ],
        curriculum: [
            Ece_paper_1,
            Ece_paper_2,
            Ece_paper_3,
            Ece_paper_4,
            Ece_paper_5,
            Ece_paper_6,
            Ece_paper_7

        ]
    },
    {
        id: 4,
        title: 'CSE',
        curriculum_name: [
            'Operating Systems Theory And Practice',
            'Modern Computer Networks',
            'Mathematics For Computer Science',
            'Internet Of Things',
            'Data Structures And Algorithms',
            'Data Science Fundamentals',
            'Ai For Cyber Security'
        ],
        curriculum: [
            CSE_paper_1,
            CSE_paper_2,
            CSE_paper_3,
            CSE_paper_4,
            CSE_paper_5,
            CSE_paper_6,
            CSE_paper_7
        ]
    },
];

const Curriculum = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Initialize to make the first item open by default

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Examination Centre" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research" id="">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div>
                                                        {programData.map(({ id, title, curriculum, curriculum_name }) => {
                                                            console.log(`Rendering Program ID: ${id}`);
                                                            console.log("Curriculum Links:", curriculum);
                                                            console.log("Curriculum Names:", curriculum_name);

                                                            return (
                                                                <React.Fragment key={id}>
                                                                    <button
                                                                        className={`accordion ${activeIndex === id ? 'active' : ''}`}
                                                                        onClick={() => toggleAccordion(id)}
                                                                    >
                                                                        <img src={Academic_calendar_image} style={{ height: '40px' }} alt={title} />
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none', marginBottom: '14px' }}>
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>I Semester</th>
                                                                                    <th style={{ textAlign: 'center' }}>M.Tech-2024</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {curriculum.map((curr, index) => {
                                                                                    if (!curr || !curriculum_name[index]) {
                                                                                        console.error(`Missing curriculum data at index ${index} for program ${title}`);
                                                                                        return null; // Skip rendering if data is missing
                                                                                    }
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{curriculum_name[index]}</td>
                                                                                            <td style={{ textAlign: 'center' }}>
                                                                                                <a href={curr} target="_blank" rel="noopener noreferrer" style={{ color: "#000" }} className='namehover'>
                                                                                                    View
                                                                                                </a>
                                                                                                {/* <img src={Linkimage} style={{ height: '25px', marginLeft: '5px' }} alt="link icon" /> */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        </table>

                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />
        </>
    );
}

export default Curriculum;
