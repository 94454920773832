import React, { useEffect } from 'react';


const Enquire = () => {
    useEffect(() => {
        // First script for the npfwpopup.js
        const script1 = document.createElement("script");
        script1.src = "https://in8cdn.npfs.co/js/widget/npfwpopup.js";
        script1.async = true;
        document.body.appendChild(script1);
    
        // Second script for the NpfWidgetsInit
        const script2 = document.createElement("script");
        script2.innerHTML = `
          let npfW0c1f75f154d4820e5f8f65e8950cdb87 = new NpfWidgetsInit({
            "widgetId":"0c1f75f154d4820e5f8f65e8950cdb87",
            "baseurl":"widgets.in8.nopaperforms.com",
            "formTitle":"Enquiry Form",
            "titleColor":"#FF0033",
            "backgroundColor":"#ddd",
            "iframeHeight":"500px",
            "buttonbgColor":"#4c79dc",
            "buttonTextColor":"#FFF",
          });
        `;
        document.body.appendChild(script2);
    
        // Clean up the script tags when the component unmounts
        return () => {
          document.body.removeChild(script1);
          document.body.removeChild(script2);
        };
      }, []);

    return (
        // <button type="button" className="npfWidgetButton npfWidget-0c1f75f154d4820e5f8f65e8950cdb87">Enquire Now!</button>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                        <div class="fixed-enquire" style={{display: "block"}}>
                            {/* <b class="side-equire smoothScroll  form-popup">Enquire Now</b> */}
                            {/* <Link to="" target="_blank" class="npfWidgetButton npfWidget-0c1f75f154d4820e5f8f65e8950cdb87"><b>Enquire Now!</b></Link> */}
                            <button type="button" class="npfWidgetButton npfWidget-0c1f75f154d4820e5f8f65e8950cdb87 enquire">Enquire Now</button>
                        </div>
                        
                </div>
            </div>
        </div>
    );
}

export default Enquire;

