import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/iqac';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';

import SideManu from './sidebar_msg';
import Chairman_img from './images/chairman.jpg';





const accreditations = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Vice Chancellor's Message" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div className="col-lg-8" style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '20px' }}>
                                                <div className="about__content about__content1">
                                                                           <center><img src={Chairman_img} alt="About" /></center>
                                                   
                                                    <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px'}}>
                                                    Aditya University, a future-focused University set in idyllic green surroundings, has always been in the forefront in providing skilled student workforce to the global industry. Very much conscious of its rural presence, it is now focused on developing technologies for rural India with a firm belief that grassroot innovations that benefit 1.4 billion people will actually have implications for 6 billion more of the world population. The technologies that we are currently focusing on are related to green energy solutions involving biogas, solar, electric and hydrogen, drone-based solutions for sustainable and precision farming, disaster prevention and mitigation, climate change and global warming, etc. with advances in AI and Machine Learning augmenting these for efficient and optimal outcomes.</p>
                                                    <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                                    Aditya University welcomes National / International collaboration with Universities and Institutions in India and abroad through joint degree and research programs as well as industry for joint development of technologies. We also welcome scientists, engineers, researchers and rural entrepreneurs that share our vision to work with us.
                                                    </p>
                                                    <b style={{ float: 'right' }}>
                                                        Best regards,<br />
                                                        Prof. M.B.Srinivas<br />
                                                        Vice Chancellor<br />
                                                        Aditya university
                                                    </b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default accreditations;