import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/iqac';
import ScrollToTop from '../../../components/ScrollTop';
// import "../../../components/Styles/pragram.css"
import SideManu from './sidebar_iqac';
import Loading from './loading.gif';
const backendUrl = process.env.REACT_APP_DATABASEURL;
const IQAC = () => {

    const [professor, setProfessor] = useState(null);

    useEffect(() => {
        const fetchProfessorData = async () => {
            try {
                const response = await axios.get(`${backendUrl}api/iqca_details`);
                setProfessor(response.data.iqacmembers);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching professor data:', error);
            }
        };
        fetchProfessorData();
    }, []);

    // if (!professor) {
    //     return <div><center><img src={Loading} alt="Loading..." /></center></div>;
    // }

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Internal Quality Assurance Cell" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                { professor ?
                                                   <div className="table-responsive table-striped" style={{width:"100%"}}>
                                                    <table className="table responsive">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>S.No</th>
                                                                <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '26%' }}>Name</th>
                                                                <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>Designation</th>
                                                                <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '20%' }}>Position in the Committee</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {professor.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                                                                    <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{item.name}</td>
                                                                     <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.designation}</td>
                                                                     <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.position}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                   : <div><center><img src={Loading} alt="Loading..." /></center></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <ScrollToTop />
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default IQAC;