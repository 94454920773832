import React, { useState } from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../../components/Breadcrumb/AboutBreadcrumbs';
import ScrollToTop from '../../../components/ScrollTop';
import Ragging from './images/ragging.jpg';
import General from './images/general.jpg';
import Women from './images/women.jpg';
import General1 from './images/student.jpg'; 
import General2 from './images/faculty.jpg';

const GrievanceRedressal = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalToggle = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <Header
                parentMenu="page"
                menuCategoryEnable="enable"
            />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Grievance Redressal" />
                    <br />
                    <div className="container" style={{ backgroundColor: "#f8f9fa", padding: '20px' }}>
                        <div className="row justify-content-center">
                            {/* General Grievances */}
                            <div className="col-lg-4 col-md-6 col-sm-12 text-center" style={{ padding: '10px' }}>
                                <img
                                    src={General}
                                    alt="General Grievances"
                                    className="img-fluid rounded shadow-sm cardimage"
                                    style={{ maxWidth: '100%', height: 'auto', cursor: 'pointer' }}
                                    onClick={handleModalToggle} // Open modal on click
                                />
                            </div>
                            {/* Women Grievances */}
                            <div className="col-lg-4 col-md-6 col-sm-12 text-center" style={{ padding: '10px' }}>
                                <a href='grievances/Women Grievances'>
                                    <img
                                        src={Women}
                                        alt="Women Grievances"
                                        className="img-fluid rounded shadow-sm cardimage"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </a>
                            </div>
                            {/* Ragging Grievances */}
                            <div className="col-lg-4 col-md-6 col-sm-12 text-center" style={{ padding: '10px' }}>
                                <a href='grievances/Ragging Grievances'>
                                    <img
                                        src={Ragging}
                                        alt="Ragging Grievances"
                                        className="img-fluid rounded shadow-sm cardimage"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Modal */}
                    {showModal && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <button className="close_button" onClick={handleModalToggle}>×</button>
                                <div className="modal-body">
                                <a href='grievances/Student Grievances'> <img
                                        src={General1}
                                        alt="General Grievance 1"
                                        className="img-fluid rounded"
                                        style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px',padding:'10px' }}
                                    />
                                </a>
                                <a href='grievances/Faculty Grievances'>
                                    <img
                                        src={General2}
                                        alt="General Grievance 2"
                                        className="img-fluid rounded"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </a>
                                </div>
                            </div>
                        </div>
                    )}

                    <br />
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
};

export default GrievanceRedressal;
