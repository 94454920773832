
import { Link } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/Leadership';
import ScrollToTop from '../../components/ScrollTop';

// Image
// import aboutImg from '../../assets/images/about/vice_chancellor.jpg';
import aboutImg from '../../assets/images/about/vice_chancellor.png';

import shapeImg1 from '../../assets/images/about/shape_02.png';

const Chancellor = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Vice Chancellor" />
                    <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
                        <div class="container">
                            <div class="row">
                                <div className="col-lg-4">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={aboutImg} alt="About" />
                                    </div>
                                </div>
                                <div className="col-lg-8" style={{backgroundColor: 'rgb(248, 249, 250)',padding:'20px'}}>
                                    <div className="about__content about__content1">
                                        {/* <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s" style={{color:"#D2093C"}}>Chancellor's Message </h2> */}
                                        {/* <b style={{fontSize:'18px',color: "#D2093C"}}>Dreams alone won't bring success; it takes sweat, determination, and action
                                        </b> */}
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}>
                                        Aditya University, a future-focused University set in idyllic green surroundings, has always been in the forefront in providing skilled student workforce to the global industry. Very much conscious of its rural presence, it is now focused on developing technologies for rural India with a firm belief that grassroot innovations that benefit 1.4 billion people will actually have implications for 6 billion more of the world population. The technologies that we are currently focusing on are related to green energy solutions involving biogas, solar, electric and hydrogen, drone-based solutions for sustainable and precision farming, disaster prevention and mitigation, climate change and global warming, etc. with advances in AI and Machine Learning augmenting these for efficient and optimal outcomes.</p>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}>
                                        Aditya University welcomes National / International collaboration with Universities and Institutions in India and abroad through joint degree and research programs as well as industry for joint development of technologies. We also welcome scientists, engineers, researchers and rural entrepreneurs that share our vision to work with us.
                                        </p>
                                        <br/>
                                      <b style={{float:'right'}}>
                                            Best regards,<br/>
                                            Prof. M.B.Srinivas<br/>
                                            Vice Chancellor<br/>
                                            Aditya university 
                                        </b>
                                    </div>
                                </div>
                                
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Chancellor;