// import React, { useEffect } from 'react';



// const Careers = () => {
//   useEffect(() => {
//     window.location.href = 'https://aec.edu.in/icmes2025/';
//   }, [])
//   return (
//   <></>    
//   )
// }

// export default Careers;


import React from 'react';

const Careers = () => {
  return (
    <div className="container-fluid" style={{ padding: 0, margin: 0 ,backgroundColor:'#fff'}}>
      {/* Making the iframe responsive */}
      <iframe
        src="https://aec.edu.in/icmes2025/"
        style={{
          width: '100%',
          height: '99vh', // Set to viewport height
          border: 'none', // Removes iframe border
        }}
        title="Careers Page"
      />
    </div>
  );
}

export default Careers;


