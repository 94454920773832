import React from 'react';
// import courses from '../../data/Scholarship.json';
import Image from './old_files/scholarships.jpg';

const Scholarship = () => {
    return (
        <div className="high_quality-section pt---110 pb---120">
            <div className="container">
                {/* <center><b style={{ color: "#D2093C", fontSize: "30px", textAlign: 'center' }}>Scholarship Details for the Academic Year 2025-26</b></center> */}
                <img src={Image} style={{width:'100%'}}></img>
                <div className="react_featured_online" style={{display:'none'}}>
                    <div className="container-flied">
                        <div class="row m-0">
                            <div class="col-md-12  col-sm-12 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                <div class="about__content">
                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: 'center' }}>Scholarships (Engineering)</b>
                                    <div class="table-responsive table-bordered table-striped ">
                                        <table class="table responsive">
                                            <thead>
                                                <tr>
                                                    <th rowSpan={2} style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "5%", textAlign: "center" }}>S.No</th>
                                                    <th rowSpan={2} colSpan={2} style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "40%", textAlign: "center" }}>Mode of Selection</th>
                                                    <th colSpan={5} style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "35%", textAlign: "center" }}>% of Concession</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>100 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>75 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>50 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>25 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>10 %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td rowSpan={2} style={{ textAlign: "center" }}>1</td>
                                                    <td style={{ textAlign: "center" }} rowSpan={2} > ASAT (%) </td>
                                                    <td style={{ textAlign: "left" }}>CSE, IT, AI&ML, DS, ECE</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>{">"}97</td>
                                                    <td style={{ textAlign: "center" }}>95 to 96.9</td>
                                                    <td style={{ textAlign: "center" }}>92 to 94.9</td>
                                                    <td style={{ textAlign: "center" }}>90 to 91.9</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>CE, EEE, ME, Ag.E, Ming.E, PT</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>{">"}95</td>
                                                    <td style={{ textAlign: "center" }}>92 to 94.9</td>
                                                    <td style={{ textAlign: "center" }}>90 to 91.9</td>
                                                    <td style={{ textAlign: "center" }}>85 to 89.9</td>
                                                </tr>

                                                <tr>
                                                    <td rowSpan={2} style={{ textAlign: "center" }}>2</td>
                                                    <td style={{ textAlign: "center" }} rowSpan={2} > JEE (Percentile) </td>
                                                    <td style={{ textAlign: "left" }}>CSE, IT, AI&ML, DS, ECE</td>
                                                    <td style={{ textAlign: "center" }}>{">"}98</td>
                                                    <td style={{ textAlign: "center" }}>{">"}95</td>
                                                    <td style={{ textAlign: "center" }}>90 to 94.9</td>
                                                    <td style={{ textAlign: "center" }}>80 to 89.9</td>
                                                    <td style={{ textAlign: "center" }}>70 to 79.9</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>CE, EEE, ME, Ag.E, Ming.E, PT </td>
                                                    <td style={{ textAlign: "center" }}>{">"}95</td>
                                                    <td style={{ textAlign: "center" }}>{">"}90</td>
                                                    <td style={{ textAlign: "center" }}>80 to 89.9</td>
                                                    <td style={{ textAlign: "center" }}>70 to 79.9</td>
                                                    <td style={{ textAlign: "center" }}>65 to 69.9</td>
                                                </tr>



                                                <tr>
                                                    <td rowSpan={2} style={{ textAlign: "center" }}> 3</td>
                                                    <td style={{ textAlign: "center" }} rowSpan={2} > EAPCET (AP&TS) (Rank) </td>
                                                    <td style={{ textAlign: "left" }}>CSE, IT, AI&ML, DS, ECE</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>1 to 5000</td>
                                                    <td style={{ textAlign: "center" }}>5001 to 10000</td>
                                                    <td style={{ textAlign: "center" }}>10001 to 25000</td>
                                                    <td style={{ textAlign: "center" }}>25001 to 50000</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>CE, EEE, ME, Ag.E, Ming.E, PT </td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>1 to 10000</td>
                                                    <td style={{ textAlign: "center" }}>10001 to 25000</td>
                                                    <td style={{ textAlign: "center" }}>25001 to 50000</td>
                                                    <td style={{ textAlign: "center" }}>50001 to 75000</td>
                                                </tr>


                                                <tr>
                                                    <td rowSpan={2} style={{ textAlign: "center" }}> 4 </td>
                                                    <td style={{ textAlign: "center" }} rowSpan={2} > BIE (AP&TS) ( %) </td>
                                                    <td style={{ textAlign: "left" }}>CSE, IT, AI&ML, DS, ECE</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>{'>'}98</td>
                                                    <td style={{ textAlign: "center" }}>96 to 97.9</td>
                                                    <td style={{ textAlign: "center" }}>94 to 95.9</td>
                                                    <td style={{ textAlign: "center" }}>92 to 93.9</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>CE, EEE, ME, Ag.E, Ming.E, PT </td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>{'>'}95</td>
                                                    <td style={{ textAlign: "center" }}>92.5 to 95</td>
                                                    <td style={{ textAlign: "center" }}>90 to 92.5</td>
                                                    <td style={{ textAlign: "center" }}>85 to 89.9</td>
                                                </tr>

                                                <tr>
                                                    <td rowSpan={2} style={{ textAlign: "center" }}> 5  </td>
                                                    <td style={{ textAlign: "center" }} rowSpan={2} >CBSE (Marks) </td>
                                                    <td style={{ textAlign: "left" }}>CSE, IT, AI&ML, DS, ECE</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>{'>'}450</td>
                                                    <td style={{ textAlign: "center" }}>425 to 449</td>
                                                    <td style={{ textAlign: "center" }}>400 to 424</td>
                                                    <td style={{ textAlign: "center" }}>375 to 399</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>CE, EEE, ME, Ag.E, Ming.E, PT  </td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>{'>'}425</td>
                                                    <td style={{ textAlign: "center" }}>400 to 425</td>
                                                    <td style={{ textAlign: "center" }}>375 to 399</td>
                                                    <td style={{ textAlign: "center" }}>350 to 374</td>
                                                </tr>

                                                <tr>
                                                    <td rowSpan={2} style={{ textAlign: "center" }}> 6  </td>
                                                    <td style={{ textAlign: "center" }} rowSpan={2}> Sports </td>
                                                    <td style={{ textAlign: "left" }}>CSE, IT, AI&ML, DS, ECE</td>
                                                    <td style={{ textAlign: "center" }}>International Level Participation</td>
                                                    <td style={{ textAlign: "center" }}>National Level Participation</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>CE, EEE, ME, Ag.E, Ming.E, PT  </td>
                                                    <td style={{ textAlign: "center" }}>International Level Participation</td>
                                                    <td style={{ textAlign: "center" }}>National Level Participation</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: 'center' }}>Scholarships (Pharmacy)</b>
                                    <div class="table-responsive table-bordered table-striped ">
                                        <table class="table responsive">
                                            <thead>
                                                <tr>
                                                    <th rowSpan={2} style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "5%", textAlign: "center" }}>S.No</th>
                                                    <th rowSpan={2} colSpan={2} style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "40%", textAlign: "center" }}>Mode of Selection</th>
                                                    <th colSpan={5} style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "35%", textAlign: "center" }}>% of Concession</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>100 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>75 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>50 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>25 %</th>
                                                    <th style={{ backgroundColor: "rgb(49, 102, 199)", color: '#fff', width: "10%", textAlign: "center" }}>10 %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>1</td>
                                                    <td style={{ textAlign: "center" }}> ASAT (%) </td>
                                                    <td style={{ textAlign: "center" }} rowSpan={4} >B.Pharmacy, Pharma-D </td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>{">"}97</td>
                                                    <td style={{ textAlign: "center" }}>95 to 96.9</td>
                                                    <td style={{ textAlign: "center" }}>92 to 94.9</td>
                                                    <td style={{ textAlign: "center" }}>90 to 91.9</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>2</td>
                                                    <td style={{ textAlign: "center" }}> EAPCET(AP&TS) (Rank) </td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>1 to 4000</td>
                                                    <td style={{ textAlign: "center" }}>4001 to 10000</td>
                                                    <td style={{ textAlign: "center" }}>10001 to 25000</td>
                                                    <td style={{ textAlign: "center" }}>25001 to 35000</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>3</td>
                                                    <td style={{ textAlign: "center" }} > Sports </td>
                                                    <td style={{ textAlign: "center" }}>International Level Participation</td>
                                                    <td style={{ textAlign: "center" }}>National Level Participation</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>4</td>
                                                    <td style={{ textAlign: "center" }} > NEET </td>
                                                    <td style={{ textAlign: "center" }}>-</td>
                                                    <td style={{ textAlign: "center" }}>1 to 10000</td>
                                                    <td style={{ textAlign: "center" }}>10001 to 15000</td>
                                                    <td style={{ textAlign: "center" }}>15001 to 25000</td>
                                                    <td style={{ textAlign: "center" }}>25001 to 35000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                              <div style={{textAlign:'left'}}>
                                {/* <b style={{ color: "#D2093C", fontSize: "20px" }}>NOTE</b><br/>
                                <span style={{textAlign:'left', fontSize:'18px'}}>1. Scholarship applicable for first year only.</span><br/>
                                <span style={{textAlign:'left' ,fontSize:'18px'}}>2. 8.0 CGPA with no backlogs in every semester is mandatory to avail the same scholarship for the up coming years.</span><br/>
                                <span style={{textAlign:'left' ,fontSize:'18px'}}>3. To avail Scholarship via CBSE/+2 marks, English, Maths, Physics & Chemistry need to be included while calculating the Percentage.</span><br/>
                                <span style={{textAlign:'left' ,fontSize:'18px'}}>4. Availment of Scholarship via Sports participation is subject to successful Scrutiny by the University constituted Committee.</span><br/> */}
                                {/* <b style={{ color: "#D2093C", fontSize: "20px" }}>Hostel Info:</b><br/>
                                <span style={{textAlign:'left', fontSize:'18px'}}>1. Hostel Fee : Non-AC - ₹ 1.0 L , AC - ₹ 1.1 L (Laundry expenses and Medical  insurance upto ₹ 2 lakhs included)</span><br/>
                                <span style={{textAlign:'left', fontSize:'18px'}}>2. Electricity bill to be paid by student as per consumption </span><br/>
                                <span style={{textAlign:'left', fontSize:'18px'}}>3. On-Campus Apollo Shine Clinic facility available for students </span><br/> */}
                              </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}


export default Scholarship;