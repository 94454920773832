import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/iqac';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';

import SideManu from './sidebar_iqac';
// import image1 from './accreditations_images/nba.png';
// import image2 from './accreditations_images/naac.png';




const accreditations = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Internal Quality Assurance Cell" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                
                                                <div class="container">
                                                    <div className="campus_sec1 row">
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/1`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 1 </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/2`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 2 </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/3`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 3 </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/4`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 4 </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="campus_sec1 row">
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/5`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 5 </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/6`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 6 </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/7`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 7 </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/8`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 8 </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="campus_sec1 row">
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/9`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 9 </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="text-center">
                                                                <Link to={`/criteria/10`} className="more-about" target="blank" style={{ width: "100%" }}> Criteria 10 </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default accreditations;