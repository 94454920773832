import { Link } from 'react-router-dom';

// Image
import aboutImg from './images/above.jpg';
import aboutBadge from '../../assets/images/about/badge.png';

const About = () => {

    return (
        <div className="about__area about__area_one p-relative pt---10 pb---120">
            <div className="container">
                <div className="row mt-1">
                    {/* <div className="col-lg-6">
                        <div className="about__image">
                            <img className="react__shape__ab" src={aboutBadge} alt="Shape Image" />
                        </div>
                    </div> */}
                    <div className="col-lg-6">
                        <div className="about__image">
                            <iframe
                                src="https://drive.google.com/file/d/1tJ98NXaltPVqZiEICgpKyLzzXSKeNvAa/preview"
                                width="100%"
                                height="350"
                                allow="autoplay"
                                title="Google Drive Video"
                            ></iframe>
                        </div>
                        <div className="campus_sec1 row mt-5">
                                <div className="col-lg-6">
                                    <div className="text-center">
                                        <Link to="/about" className="more-about" target="blank" style={{ width: "100%" }}> About <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-1">
                                    <div className="text-center">
                                        <Link to="/programsoffered" className="more-about" target="blank" style={{ width: "100%" }}> Programmes offered <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                    </div>
                                </div>
                            </div> 
                    </div>
                            
                    <div className="col-lg-6">
                        <div className="about__content" style={{ marginTop: '-56px' }}>
                            {/* <img src={aboutImg} alt="About" /> */}
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Welcome to  <em> Aditya University</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Embark on a transformative journey towards academic excellence and personal growth. <br /> Aditya University opens the doors to a world of possibilities, where education transcends boundaries and empowers you to shape your future. At Aditya University, education is more than  a degree it's a pathway to unlocking your full potential and making a meaningful impact. The campus, set in a vibrant environment, attracts students from more than 20 countries, creating a diverse and enriching academic experience. This multicultural atmosphere fosters collaboration and innovation, preparing students for a global workforce.</p>
                            {/* <div className="campus_sec1 row">
                                <div className="col-lg-6">
                                    <div className="text-center">
                                        <Link to="/about" className="more-about" target="blank" style={{ width: "100%" }}> About <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="text-center">
                                        <Link to="/programsoffered" className="more-about" target="blank" style={{ width: "100%" }}> Programmes offered <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;