import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Loading from '../loading.gif';
import Profile from '../man.png';

const backendUrl = process.env.REACT_APP_DATABASEURL;
const imgUrl = process.env.REACT_APP_IMGURL;

const FacultyDetails = ({ department }) => {
    const [professor, setProfessor] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState('');

    useEffect(() => {
        const fetchProfessorData = async () => {
            console.log(backendUrl)
            try {
                const response = await axios.get(`${backendUrl}api/Department_name/${department}`);
                setProfessor(response.data["staffDetails"]);
            } catch (error) {
                console.error('Error fetching professor data:', error);
            }
        };
        fetchProfessorData();
    }, [department]);

    const openModal = (imageUrl,name) => {
        setSelectedImage(imageUrl);
        setSelectedName(name);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setSelectedName('');
    };

    if (!professor) {
        return <div><center><img src={Loading}/></center></div>;
    }

    return (
        <>
            <br />
            <div className="container">
                <div className="about__content">
                    <div className="row">
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ background: 'rgb(21 106 187)', color: '#ffff', textAlign: 'center', verticalAlign: 'middle' }}>S.NO</th>
                                        <th style={{ background: 'rgb(21 106 187)', color: '#ffff', textAlign: 'center', verticalAlign: 'middle',width: '400px' }}>NAME</th>
                                        <th style={{ background: 'rgb(21 106 187)', color: '#ffff', textAlign: 'center', verticalAlign: 'middle',width: '200px' }}>QUALIFICATION</th>
                                        <th style={{ background: 'rgb(21 106 187)', color: '#ffff', textAlign: 'center', verticalAlign: 'middle',width: '300px' }}>DESIGNATION</th>
                                        <th style={{ background: 'rgb(21 106 187)', color: '#ffff', textAlign: 'center', verticalAlign: 'middle',width: '100px' }}>PHOTO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {professor.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{item.Name}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.Qualification}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.Designation}</td>
                                            {/* <td>
                                                <img 
                                                    src={`${backendUrl}../staff_photos/` + item.Photo} 
                                                    style={{ width: '100px', height: '100px', cursor: 'pointer' }} 
                                                    alt="Professor"
                                                    onClick={() => openModal(`${backendUrl}../staff_photos/` + item.Photo,item.Name)}
                                                />
                                            </td> */}
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                {item.Photo ? (
                                                    <img
                                                        src={`${backendUrl}staff_photos/${item.Photo}`}
                                                        style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                                        alt="Professor"
                                                        onClick={() => openModal(`${backendUrl}staff_photos/${item.Photo}`, item.Name)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={Profile}
                                                        style={{ width: '100px', height: '100px' }}
                                                        alt="Default Profile"
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br />
            </div>

            <Modal
                isOpen={!!selectedImage}
                onRequestClose={closeModal}
                contentLabel="Image Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    },
                    content: {
                        position: 'relative',
                        inset: 'auto',
                        padding: '20px',
                        background: '#fff',
                        borderRadius: '4px',
                        maxWidth: '50%',
                        maxHeight: '50%',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                }}
            >
                <button 
                    onClick={closeModal} 
                    style={{ 
                        position: 'absolute', 
                        top: '10px', 
                        right: '10px', 
                        padding: '0px 6px', 
                        // background: '#f8f9fa',
                        border: '1px solid #ccc', 
                        borderRadius: '4px', 
                        cursor: 'pointer',
                        background: 'rgb(21 106 187)', 
                        color: '#ffff',
                        
                    }}
                >
                    Close
                </button>
                <div style={{ textAlign: 'center' }}>
                    <img src={selectedImage} alt="Selected" style={{ maxWidth: '30%', maxHeight: '30%' }} /><br/>
                    <b style={{color:'#d3143f'}}>{selectedName}</b>
                </div>
            </Modal>
        </>
    );
};

export default FacultyDetails;
