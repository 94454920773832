import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
// import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import Slider from 'react-slick';
import sliderImg1 from './images/newplacement1.jpg';
import sliderImg4 from './images/newplacement6.jpg';

import slider2 from './images/newplacement2.jpg';
import slider3 from './images/newplacement3.jpg';
import slider4 from './images/newplacement4.jpg';
import slider5 from './images/newplacement5.jpg';
import slider01 from './images/newplacement7.jpg';
import slider02 from './images/newplacement8.jpg';
import image31lpa from './images/newplacement9.jpg';
import image17 from './images/newplacement10.jpg';
import image18 from './images/newplacement11.jpg';


import sliderImg2 from './images/newplacement13.jpg';
import sliderImg3 from './images/newplacement12.jpg';
import slider14 from './images/newplacement14.jpg';


const HomeSlider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');

    // Function to open modal with specific video ID
    const openModalWithVideo = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-slider-part">
                {/* <StudyBreadcrumb pageTitle="Placement" /> */}
                <div className="high_quality-section pt---110 pb---120">
                    <div className="container">
                        <div className="react__title__section-all">
                            <div className="research" id="">
                                <div className="container">
                                    <div className="row m-0">
                                        <div className="home-sliders home2 mt-2">
                                            <Slider {...sliderSettings}>
                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={slider01} alt="Slider Image 1" />
                                                        <img className="mobile" src={slider01} alt="Slider Image 1" />
                                                    </div>
                                                </div>
                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={sliderImg4} alt="Slider Image 1" />
                                                        <img className="mobile" src={sliderImg4} alt="Slider Image 1" />
                                                    </div>
                                                </div>
                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={slider02} alt="Slider Image 1" />
                                                        <img className="mobile" src={slider02} alt="Slider Image 1" />
                                                    </div>
                                                </div>
                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={image31lpa} alt="Slider Image 1" />
                                                        <img className="mobile" src={image31lpa} alt="Slider Image 1" />
                                                    </div>
                                                </div>
                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={sliderImg1} alt="Slider Image 1" />
                                                        <img className="mobile" src={sliderImg1} alt="Slider Image 1" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={slider2} alt="Slider Image 1" />
                                                        <img className="mobile" src={slider2} alt="Slider Image 1" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={image18} alt="Slider Image 1" />
                                                        <img className="mobile" src={image18} alt="Slider Image 1" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={slider3} alt="Slider Image 1" />
                                                        <img className="mobile" src={slider3} alt="Slider Image 1" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={image17} alt="Slider Image 1" />
                                                        <img className="mobile" src={image17} alt="Slider Image 1" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={slider4} alt="Slider Image 1" />
                                                        <img className="mobile" src={slider4} alt="Slider Image 1" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={sliderImg3} alt="Slider Image 2" />
                                                        <img className="mobile" src={sliderImg3} alt="Slider Image 2" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                    <div className="slider-img">
                                                        <img className="desktop" src={slider5} alt="Slider Image 1" />
                                                        <img className="mobile" src={slider5} alt="Slider Image 1" />
                                                    </div>
                                                </div>

                                                <div className="single-slide">
                                                   <div className="slider-img">
                                                        <img className="desktop" src={sliderImg2} alt="Slider Image 2" />
                                                        <img className="mobile"  src={sliderImg2} alt="Slider Image 2" />
                                                      </div>
                                                     </div>
                                                     <div className="single-slide">
                                                   <div className="slider-img">
                                                        <img className="desktop" src={slider14} alt="Slider Image 2" />
                                                        <img className="mobile"  src={slider14} alt="Slider Image 2" />
                                                      </div>
                                                     </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId} onClose={() => setIsOpen(false)} />

            </div>
        </>
    );
};

export default HomeSlider;
