
import { Link } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/Leadership';
import ScrollToTop from '../../components/ScrollTop';

// Image
import Registrar from './images/Registrar.JPG';


const Deputy_Chancellor = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Registrar" />
                    <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
                        <div class="container">
                            <div class="row">
                                {/* <div className="col-lg-4">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={Deputy_Chancellor_image} alt="About" />
                                    </div>
                                </div> */}
                                <div className="offset-1 col-lg-10" style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '20px' }}>
                                    <div className="about__content about__content1">
                                        <center><img src={Registrar} alt="About" style={{width:'55%'}} /></center>

                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                            It is with great pleasure and pride that I welcome you all to our esteemed University, a vibrant hub of learning, innovation, and cultural exchange. As you begin this transformative journey, I would like to highlight the qualities that make our institution a preferred destination for academic excellence and holistic development.
                                       
                                            Our university's strong reputation is built on our commitment to quality education, validated by prestigious accreditations and consistent recognition in rankings. These accolades reflect our dedication to maintaining global standards in teaching, research, and community engagement. Our industry-aligned curricula are designed to equip students with the skills to excel in today's dynamic professional world, ensuring they are both job- and future-ready.
                                        </p>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                            Diversity and inclusivity lie at the heart of our university's ethos. With a student body representing various states and nations, we provide a vibrant and multicultural environment that fosters collaboration, mutual respect, and the exchange of ideas.  This diversity enriches the learning experience, preparing our students to become global citizens who value collaboration and empathy.
                                       
                                            To our esteemed parents, we assure you that your children are in a nurturing and stimulating environment where their aspirations will be supported and their potential realized. To our students, I encourage you to immerse yourselves fully in the opportunities available, be it academic pursuits, research initiatives, or extracurricular activities. Our dedicated faculty and staff are here to guide and inspire you every step of the way.
                                        </p>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify', fontSize: '18px' }}>
                                            Let us come together to forge a legacy of innovation, excellence, and meaningful impact.
                                            <br />
                                            Welcome to a journey of learning without limits!
                                        </p>
                                        <b style={{ float: 'right' }}>
                                            Best regards,<br />
                                            Dr. G. Suresh<br />
                                            Registrar<br />
                                            Aditya university<br />
                                            
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Deputy_Chancellor;